import { Paginate } from "@elevatejs/material-crud-ui";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import LinearProgress from "@material-ui/core/LinearProgress";
import Menu from "@material-ui/core/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RefreshIcon from "@material-ui/icons/Refresh";
import Typography from "@material-ui/core/Typography";
import FilterIcon from "@material-ui/icons/FilterList";
import React, { FC, ReactNode, useState } from "react";
import { useIsMdUp } from "../../components/Layout/utils";
import { useFlasher } from "../../utils/flasher";
import { IconButton } from "@material-ui/core";

export const ExpandableTable: FC<{
  expanded: boolean;
  setExpanded: (v: boolean) => any;
  title: string;
  loading?: boolean;
  filter?: ReactNode;
  onRefresh?: () => any;
}> = ({ expanded, setExpanded, title, loading, filter, children, onRefresh }) => {
  const ref = useFlasher();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMdUp = useIsMdUp();
  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      ref={ref}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Box display="flex" alignItems="center">
          {filter ? (
            <Box mr={2}>
              <Fab
                size="small"
                onClick={(ev: any) => {
                  ev.stopPropagation();
                  setAnchorEl(ev.target);
                }}
                color="primary"
              >
                <FilterIcon />
              </Fab>
            </Box>
          ) : null}
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClick={(ev: any) => {
            ev.stopPropagation();
          }}
          onClose={(ev: any) => {
            ev.stopPropagation();
            setAnchorEl(null);
          }}
        >
          {filter}
        </Menu>
      </AccordionSummary>
      {loading ? <LinearProgress /> : <Divider />}
      <AccordionDetails style={{ padding: 0 }}>
        <div
          className="scroll-md"
          style={
            isMdUp
              ? { height: 376, width: "100%" }
              : { minHeight: 376, width: "100%" }
          }
        >
          {children}
        </div>
      </AccordionDetails>

      {expanded ? (
        <>
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Paginate rowsPerPageOptions={[5, 10, 20]} component="div" />
            {onRefresh ? 
              <IconButton onClick={() => onRefresh()}>
                <RefreshIcon />
              </IconButton> : null}
          </Box>
        </>
      ) : null}
    </Accordion>
  );
};
