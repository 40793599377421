import {
  FindContextProvider,
  PaginationProvider,
  useFindContext,
  usePagination,
} from "@elevatejs/material-crud-ui";
import {
  CardUserSubscriptionsIosS2SDocument,
  IosInAppSubscriptionS2S,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { FC, useMemo } from "react";
import { SubscriptionList } from "../../components/SubscriptionsS2SIos/List";
import { ExpandableTable } from "../../components/Table/Expandable";
import {
  useSetUserSubscriptionsS2SIosExpanded,
  useUserSubscriptionsS2SIosExpanded,
  useActiveSubscriptionS2SIosHawk,
  useSetActiveSubscriptionS2SIosHawk,
  useUserControlsContext
} from "./controls";

export const SubscriptionsS2SIos = () => {
  return (
    <PaginationProvider initialPerPage={5}>
      <SubscriptionsS2SIosProvider>
        <SubscriptionsS2SIosComponent />
      </SubscriptionsS2SIosProvider>
    </PaginationProvider>
  );
};

export const SubscriptionsS2SIosProvider: FC = ({
  children,
}) => {
  const { userId } = useUserControlsContext()
  const expanded = useUserSubscriptionsS2SIosExpanded();
  const [{ page, perPage }] = usePagination();
  const variables = useMemo(() => ({ userId, page, perPage }), [
    userId,
    page,
    perPage,
  ]);
  const options = useMemo(() => ({ skip: !expanded, notifyOnNetworkStatusChange: true }), [expanded]);
  return (
    <FindContextProvider
      typeName="IosInAppSubscriptionS2S"
      variables={variables}
      options={options}
      findDocument={CardUserSubscriptionsIosS2SDocument}
      selectFindData={(v, data) =>
        data?.cardUserById?.getSubscriptionIosS2SHistory?.data ?? []
      }
      selectFindTotal={(v, data) =>
        data?.cardUserById?.getSubscriptionIosS2SHistory?.total ?? 0
      }
    >
      {children}
    </FindContextProvider>
  );
};

export const SubscriptionsS2SIosComponent: FC = () => {
  const { data, loading, refetch } = useFindContext<IosInAppSubscriptionS2S[]>();
  const activeSubscription = useActiveSubscriptionS2SIosHawk();
  const setActiveSubscription = useSetActiveSubscriptionS2SIosHawk();
  const expanded = useUserSubscriptionsS2SIosExpanded();
  const setExpanded = useSetUserSubscriptionsS2SIosExpanded();
  return (
    <ExpandableTable
      title="Subscriptions S2S iOS"
      loading={loading}
      expanded={expanded}
      setExpanded={() => setExpanded(!expanded)}
      onRefresh={refetch}
    >
      <SubscriptionList
        activeSubscription={activeSubscription}
        setActiveSubscription={(v) => setActiveSubscription(v)}
        subscriptions={data}
        loading={loading}
      />
    </ExpandableTable>
  );
};
