import { PlaidTransaction, AtriumTransaction } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";
import { Marker } from "../../components/Map";
import { locationToCoords, hasLocationLatLon, hasLatitudeLongitude, backgroundLocationToCoords } from "../../utils/location";
import { formatNumber } from "../../utils/number";

export const TransactionMarkers = ({ transactions, onClick }: { transactions: (PlaidTransaction|undefined|null)[], onClick?: (transaction: PlaidTransaction) => any }) => {
  return (
    <>
      {transactions.filter(hasLocationLatLon).map((transaction: PlaidTransaction, i: number) => (
        <Marker
          className="marker"
          key={transaction.transaction_id || i}
          coordinates={locationToCoords(transaction)}
          onClick={() => onClick && onClick(transaction)}
        >
          {`$${formatNumber(transaction.amount || 0)}`}
        </Marker>
      ))}
    </>
  );
};


export const AtriumTransactionMarkers = ({ transactions, onClick }: { transactions: (AtriumTransaction|undefined|null)[], onClick?: (transaction: AtriumTransaction) => any }) => {
  return (
    <>
      {transactions.filter(hasLatitudeLongitude).map((transaction: AtriumTransaction, i: number) => (
        <Marker
          className="marker"
          key={transaction.guid || i}
          coordinates={backgroundLocationToCoords(transaction)}
          onClick={() => onClick && onClick(transaction)}
        >
          {`$${formatNumber(transaction.amount || 0)}`}
        </Marker>
      ))}
    </>
  );
}

export default TransactionMarkers