import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import React, { FC } from "react";
import Details from "../../components/Transactions/Details";
import { TransactionControls } from "./controls";
import {
  TransactionActivityDocument,
  useSetTransactionVerificationStatusMutation,
  useClassifyTransactionMutation,
  TransactionVerificationStatus,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";

export const TransactionDetails: FC = () => {
  const {
    transaction,
    loading,
    activityVariables,
  } = TransactionControls.useControls();
  const [classifyTransaction, classify] = useClassifyTransactionMutation({
    refetchQueries: [
      { query: TransactionActivityDocument, variables: activityVariables },
    ],
  });
  const [verify, update] = useSetTransactionVerificationStatusMutation({
    refetchQueries: [
      { query: TransactionActivityDocument, variables: activityVariables },
    ],
  });
  return (
    <Card>
      <CardContent>
        <Details loading={loading} transaction={transaction} />
      </CardContent>
      <CardActions style={{ justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          disabled={!transaction || classify.loading}
          onClick={() => {
            const transaction_id = transaction?.transaction_id;
            if (transaction_id) {
              classifyTransaction({
                variables: {
                  transaction_id,
                },
              });
            }
          }}
        >
          Classify
        </Button>
        <Button
          color={
            transaction?.verification_status ===
            TransactionVerificationStatus.Falsified
              ? undefined
              : "secondary"
          }
          variant="contained"
          disabled={!transaction || update.loading}
          onClick={() => {
            const status =
              transaction?.verification_status ===
              TransactionVerificationStatus.Falsified
                ? TransactionVerificationStatus.Undetermined
                : TransactionVerificationStatus.Falsified;
            if (transaction?.transaction_id) {
              verify({
                variables: {
                  transaction_id: transaction?.transaction_id,
                  verification_status: status,
                },
              });
            }
          }}
        >
          Reject
        </Button>
        <Box ml={1}>
          <Button
            color={
              transaction?.verification_status ===
              TransactionVerificationStatus.Verified
                ? undefined
                : "primary"
            }
            variant="contained"
            disabled={!transaction || update.loading}
            onClick={() => {
              const status =
                transaction?.verification_status ===
                TransactionVerificationStatus.Verified
                  ? TransactionVerificationStatus.Undetermined
                  : TransactionVerificationStatus.Verified;
              if (transaction?.transaction_id) {
                verify({
                  variables: {
                    transaction_id: transaction?.transaction_id,
                    verification_status: status,
                  },
                });
              }
            }}
          >
            Verify
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
};
