import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import { BoldBalance } from "./Balance";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { PlaidTransaction } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import { TransactionVerificationStatusIcon } from "./VerificationStatusIcon";
import clsx from "clsx";
import "firebase/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
    overflow: "hidden",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

export interface DetailsProps {
  className?: string;
  transaction?: PlaidTransaction | null;
  loading?: boolean;
}

export const Details: FC<DetailsProps> = ({
  transaction,
  loading,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {loading ? (
        <Skeleton variant="circle" className={classes.avatar} />
      ) : (
        <Tooltip
          title={<Typography>{transaction?.verification_reason}</Typography>}
        >
          <Avatar alt="Category" className={classes.avatar}>
            <TransactionVerificationStatusIcon
              status={transaction?.verification_status}
            />
          </Avatar>
        </Tooltip>
      )}
      {loading ? (
        <Skeleton
          variant="rect"
          height={30}
          width={180}
          className={classes.name}
        />
      ) : (
        <Typography
          className={classes.name}
          variant="h4"
          noWrap
          style={{ maxWidth: "100%" }}
        >
          {(transaction && transaction.name) || ""}&nbsp;
        </Typography>
      )}
      {loading ? (
        <Skeleton
          variant="rect"
          height={17}
          width={120}
          className={classes.name}
        />
      ) : (
        <BoldBalance value={transaction?.amount ?? 0} />
      )}
      {loading ? (
        <Skeleton
          variant="rect"
          height={17}
          width={120}
          className={classes.name}
        />
      ) : (
        <Typography variant="body2" noWrap style={{ maxWidth: "100%" }}>
          {transaction
            ? new Date(transaction.created_date || 0).toLocaleString()
            : ""}
          &nbsp;
        </Typography>
      )}
      {loading ? (
        <Skeleton
          variant="rect"
          height={17}
          width={120}
          className={classes.name}
        />
      ) : (
        <Typography variant="body2" noWrap style={{ maxWidth: "100%" }}>
          {transaction?.date ? transaction.date.split("T")[0] : ""}
          &nbsp;
        </Typography>
      )}
    </div>
  );
};

export default Details;
