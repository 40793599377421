import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import React, { ReactNode } from "react";

interface TableListProps<V> {
  loading?: boolean;
  items?: (V | null)[];
  getItemId?: (v: V, i: number) => string|number
  renderItem?: (v: V, i: number) => ReactNode
  children?: ReactNode
}

const defaultGetItemId = (v: any, i: number) => v?.['id'] ?? i

export function TableList<V> ({
  loading,
  items = [],
  children,
  getItemId = defaultGetItemId,
  renderItem = () => <></>
}: TableListProps<V>) {
  return (
    <Paper elevation={0} style={{ marginTop: -60, overflow: "hidden" }}>
      <Box style={{ height: 60 }}></Box>
      {items.length ? (
        <List>
          {items.map(
            (item, i) =>
            item && (
                <Slide
                  direction="up"
                  in={true}
                  key={getItemId(item, i)}
                >
                  <Box>
                    {renderItem(item, i)}
                  </Box>
                </Slide>
              )
          )}
        </List>
      ) : !loading ? (
        <Slide direction="up" in={true}>
          <Box py={5}>
            {children}
          </Box>
        </Slide>
      ) : null}
    </Paper>
  );
};
