import React, { FC } from 'react'
import CheckIcon from '@material-ui/icons/Check'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import WarningSharpIcon from '@material-ui/icons/WarningSharp'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import PaymentIcon from '@material-ui/icons/Payment';
import RestoreIcon from '@material-ui/icons/Restore';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { IosInAppSubscriptionS2S } from '@xyo-network/coin-plaid-client/build/lib/graphql.generated'

export const NotificationTypeIcon: FC<{ notification_type: IosInAppSubscriptionS2S['notification_type']|undefined|null }> = ({ notification_type, ...props }) => {
  switch (notification_type) {
    case 'CANCEL': return  <CancelOutlinedIcon {...props} />
    case 'DID_CHANGE_RENEWAL_PREF': return <ChangeHistoryIcon {...props} />
    case 'DID_CHANGE_RENEWAL_STATUS': return <ChangeHistoryIcon {...props} />
    case 'DID_FAIL_TO_RENEW': return <WarningSharpIcon {...props} />
    case 'DID_RECOVER': return <RestoreIcon {...props} />
    case 'INITIAL_BUY': return <PaymentIcon {...props} />
    case 'INTERACTIVE_RENEWAL': return <CheckIcon {...props} />
    case 'RENEWAL': return <RestoreIcon {...props} />
    case 'REFUND': return <RotateLeftIcon {...props} />
    default: return null
  }
}

export default NotificationTypeIcon

// CANCEL
// Indicates that either Apple customer support canceled the subscription or the user upgraded their subscription. The cancellation_date key contains the date and time of the change.

// DID_CHANGE_RENEWAL_PREF
// Indicates the customer made a change in their subscription plan that takes effect at the next renewal. The currently active plan is not affected.

// DID_CHANGE_RENEWAL_STATUS
// Indicates a change in the subscription renewal status. Check auto_renew_status_change_date_ms and auto_renew_status in the JSON response to know the date and time of the last status update and the current renewal status.

// DID_FAIL_TO_RENEW
// Indicates a subscription that failed to renew due to a billing issue. Check is_in_billing_retry_period to know the current retry status of the subscription, and grace_period_expires_date to know the new service expiration date if the subscription is in a billing grace period.

// DID_RECOVER
// Indicates a successful automatic renewal of an expired subscription that failed to renew in the past. Check expires_date to determine the next renewal date and time.

// INITIAL_BUY
// Occurs at the user's initial purchase of the subscription. Store latest_receipt on your server as a token to verify the user’s subscription status at any time by validating it with the App Store.

// INTERACTIVE_RENEWAL
// Indicates the customer renewed a subscription interactively, either by using your app’s interface, or on the App Store in the account's Subscriptions settings. Make service available immediately.

// RENEWAL
// Indicates a successful automatic renewal of an expired subscription that failed to renew in the past. Check expires_date to determine the next renewal date and time.

// REFUND
// Indicates that App Store successfully refunded a transaction. The cancellation_date_ms contains the timestamp of the refunded transaction; the original_transaction_id and product_id identify the original transaction and product, and cancellation_reason contains the reason.
