import {
  FindContextProvider,
  PaginationProvider,
  useFindContext,
  usePagination,
} from "@elevatejs/material-crud-ui";
import {
  CardUserTransactionsQuery,
  CardUserTransactionsDocument,
  PlaidTransaction,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { FC, useMemo } from "react";
import { ExpandableTable } from "../../components/Table/Expandable";
import {
  TransactionList,
  TransactionsNotFound,
} from "../../components/Transactions";
import {
  useSetUserTransactionsExpanded,
  useSetVerifyTransactionHawk,
  useUserTransactionsExpanded,
  useVerifyTransactionHawk,
  useUserControlsContext,
} from "./controls";
import { InstitutionsList, useInstitutionControls, isPlaidInstitution } from "../../components/Institutions";

export const CardUserTransactions = () => {
  return (
    <PaginationProvider initialPerPage={5}>
      <CardUserTransactionsProvider>
        <CardUserTransactionsComponent />
      </CardUserTransactionsProvider>
    </PaginationProvider>
  );
};

export const CardUserTransactionsProvider: FC = ({ children }) => {
  const { userId } = useUserControlsContext();
  const [{ page, perPage }] = usePagination();
  const expanded = useUserTransactionsExpanded();
  const variables = useMemo(() => ({ userId, page, perPage }), [
    userId,
    page,
    perPage,
  ]);
  const options = useMemo(() => ({ skip: !expanded, notifyOnNetworkStatusChange: true }), [expanded]);
  return (
    <FindContextProvider
      typeName="Transaction"
      variables={variables}
      options={options}
      findDocument={CardUserTransactionsDocument}
      selectFindData={(v, data) => data?.cardUserById}
      selectFindTotal={(v, data) =>
        data?.cardUserById?.getCachedTransactions?.total_transactions ?? 0
      }
    >
      {children}
    </FindContextProvider>
  );
};

export const CardUserTransactionsComponent: FC = () => {
  const { data, loading, refetch } = useFindContext<CardUserTransactionsQuery['cardUserById']>();
  const institutions = data?.getAccessTokens?.map(token => token?.institution)?.filter(isPlaidInstitution) ?? []
  // const accounts = data?.getCachedTransactions?.accounts
  const transactions = data?.getCachedTransactions?.transactions ?? []
  const expanded = useUserTransactionsExpanded();
  const setExpanded = useSetUserTransactionsExpanded();
  const verifyTransaction = useVerifyTransactionHawk();
  const setVerifyTransaction = useSetVerifyTransactionHawk();
  const institutionControls = useInstitutionControls(institutions)
  return (
    <ExpandableTable
      title="Transactions"
      loading={loading}
      expanded={expanded}
      filter={<InstitutionsList {...institutionControls}  />}
      setExpanded={() => setExpanded(!expanded)}
      onRefresh={refetch}
    >
      <TransactionList
        activeTransaction={verifyTransaction}
        setActiveTransaction={(v) => setVerifyTransaction(v)}
        transactions={transactions}
        loading={loading}
      />
    </ExpandableTable>
  );
};
