import React, { PropsWithChildren } from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useAsyncState } from "../../utils/controls";

export function LoadingButton<V>({
  disabled,
  children,
  onSuccess,
  onError,
  fn,
  ...props
}: PropsWithChildren<
  ButtonProps & {
    loading?: boolean;
    fn?: () => Promise<V>;
    onSuccess?: (data: V) => void;
    onError?: (err: any) => void;
  }
>) {
  const [
    { loading, data, error },
    { setLoading, setData, setError },
  ] = useAsyncState();
  const handleClick = async (ev: any) => {
    if (typeof fn === "function") {
      try {
        setLoading();
        const data = await fn();
        setData(data);
        onSuccess?.(data)
      } catch (e) {
        setError(e.message);
        onError?.(e)
      }
    } else if (typeof props.onClick === "function") {
      props.onClick(ev);
    }
  };

  return (
    <Button
      disabled={disabled || props.loading || loading}
      style={{ position: "relative", ...props.style }}
      onClick={handleClick}
      {...props}
    >
      {props.loading || loading ? (
        <LinearProgress
          style={{ position: "absolute", top: 0, left: 0, width: "100%" }}
        />
      ) : null}
      {children}
    </Button>
  );
}
