import { PlaidTransaction } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";
import { TableList } from "../Table/List";
import TransactionListItem from "./ListItem";
import TransactionsNotFound from "./NotFound";

interface TransactionListProps {
  loading?: boolean;
  activeTransaction?: PlaidTransaction | null;
  setActiveTransaction?: (v: PlaidTransaction | null) => any;
  transactions?: (PlaidTransaction | null)[];
}

export const TransactionList = ({
  loading,
  transactions = [],
  activeTransaction,
  setActiveTransaction,
}: TransactionListProps) => {
  return (
    <TableList
      loading={loading}
      items={transactions}
      getItemId={(transaction, i) => transaction.transaction_id || i}
      renderItem={(transaction, i) => (
        <TransactionListItem
          transaction={transaction}
          active={transactions === activeTransaction}
          onClick={
            setActiveTransaction
              ? () =>
                  setActiveTransaction(
                    transaction === activeTransaction ? null : transaction
                  )
              : undefined
          }
        />
      )}
    >
      <TransactionsNotFound />
    </TableList>
  );
};
