import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { AtriumTransaction } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";
import { BoldBalance } from "../Transactions/Balance";
import TransactionVerificationStatusIcon from "../Transactions/VerificationStatusIcon";

interface TransactionListItemProps {
  active?: boolean;
  transaction: AtriumTransaction;
  onClick?: () => any;
}

export const TransactionListItem = ({
  active,
  transaction,
  onClick,
}: TransactionListItemProps) => {
  return (
    <ListItem onClick={onClick} selected={active} button>
      <ListItemAvatar>
        <Avatar>
          <TransactionVerificationStatusIcon
            status={transaction.verification_status}
          />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={transaction.description ?? ""}
        primaryTypographyProps={{ noWrap: true }}
        secondary={new Date(transaction.transactedAt).toLocaleString() ?? ""}
        secondaryTypographyProps={{ noWrap: true }}
      ></ListItemText>
      <BoldBalance value={transaction.amount} />
    </ListItem>
  );
};

export default TransactionListItem;
