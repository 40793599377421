import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { plaidClient } from "../../providers/Plaid";
import { auth } from "firebase/app";
import "firebase/auth";

export default ({ open, onClose }: { open: boolean; onClose: () => any }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Confirm Logout</DialogTitle>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() =>
            auth()
              .signOut()
              .then(() => plaidClient.resetStore())
          }
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
