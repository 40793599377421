import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { CardUser } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import { getInitials } from "../../utils/string";
import React from "react";

export const UserDetails = ({ user }: { user: CardUser }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          User Details
        </Typography>
        <Box display="flex" alignItems="center">
          <Avatar src={user?.photoURL ?? ""}>
            {getInitials(user?.displayName ?? user?.email)}
          </Avatar>
          <Box ml={2} flex={1} overflow="hidden">
            <Typography noWrap>{user?.displayName ?? ""}</Typography>
            <Typography variant="caption" noWrap>
              {user?.email ?? ""}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export const UserListItem = ({
  user,
  component,
  to,
  ...props
}: ListItemProps & { user: CardUser; to?: string; component?: any }) => {
  return (
    <ListItem {...(props as any)} component={component} to={to}>
      <ListItemAvatar>
        <Avatar src={user?.photoURL ?? ""}>
          {getInitials(user?.displayName ?? user?.email)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={user?.displayName ?? ""}
        secondary={user?.email ?? ""}
      />
    </ListItem>
  );
};

export default UserDetails;
