import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const useIsSmUp = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true
  });
}

export const useIsMdUp = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  });
}

export const useIsLgUp = () => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });
}