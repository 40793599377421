import memoize from "lodash/memoize"
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import times from "lodash/times";

export default memoize((
  data: any,
  property: string = "yyyymmddhh",
  colors: string[] = primaryGradient
) => ({
  type: "circle",
  minzoom: 14,
  paint: {
    // increase the radius of the circle as the zoom level and dbh value increases
    "circle-radius": {
      property: "speed",
      type: "exponential",
      stops: [
        [{ zoom: 15, value: 0 }, 5],
        [{ zoom: 15, value: 62 }, 10],
        [{ zoom: 22, value: 0 }, 20],
        [{ zoom: 22, value: 62 }, 50],
      ],
    },
    "circle-color": "rgba(25,133,161,1)",
    "circle-stroke-color": "white",
    "circle-stroke-width": 1,
    "circle-opacity": {
      stops: [
        [14, 0],
        [15, 1],
      ],
    },
    // ...colorRangeOptions(data, property, colors),
  },
}));

function colorRangeOptions(data: any, property: string, colors: string[]) {
  const min =
    minBy<any>(data?.features, `properties.${property}`)?.properties?.[
      property
    ] || 0;
  const max =
    maxBy<any>(data?.features, `properties.${property}`)?.properties?.[
      property
    ] || 0;
  const range = max - min;
  const mid = times(colors.length - 2).map(
    (_, i) => min + ((i + 1) / (colors.length - 1)) * range
  );
  const stopValues = [min, ...mid, max];
  console.log(stopValues)
  return {
    "circle-color": {
      property,
      type: "exponential",
      stops: stopValues.map((value, i) => [value, colors[i]]),
    },
  };
}

// export const rainbowGradient = [
//   "red",
//   "orange",
//   "yellow",
//   "green",
//   "blue",
//   "purple",
// ];

export const primaryGradient = [
  "rgba(76,92,104,0)",
  "rgba(76,92,104,0.4)",
  "rgba(76,92,104,0.8)",
  "rgba(25,133,161,0.4)",
  "rgba(25,133,161,0.8)",
  "rgba(25,133,161,1)",
];
