import Box from "@material-ui/core/Box";
import InputBase from "@material-ui/core/InputBase";
import LinearProgress from "@material-ui/core/LinearProgress";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import {
  createStyles,
  fade,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import CloseCircleIcon from "@material-ui/icons/CloseOutlined";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useCardUserListQuery } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AccountsNotFound } from "../Accounts";
import { UserListItem } from "../User";

export default React.memo(() => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const searchElement = useRef(null);
  const { data, loading } = useCardUserListQuery({
    skip: !value,
    variables: {
      searchText: value,
      pagination: {
        page: 1,
        perPage: 4,
      },
    },
  });
  const results = data?.cardUserList?.data ?? [];
  // const resultCount = data?.cardUserList?.total ?? 0
  useEffect(() => {
    if (value) setOpen(true);
  }, [value]);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div ref={searchElement}>
        <Box className={classes.search}>
          <Box className={classes.searchIcon}>
            <SearchIcon />
          </Box>
          <InputBase
            placeholder="Search…"
            value={value}
            onChange={(ev) => setValue(ev.target.value)}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
          {value ? (
            <Box className={classes.clearIcon}>
              <IconButton size="small" onClick={() => setValue('')}>
                <CloseCircleIcon />
              </IconButton>
            </Box>
          ) : null}
          <Popper
            anchorEl={searchElement.current}
            open={open}
            placement="bottom"
            style={{ zIndex: 2 }}
          >
            <Paper>
              {loading ? <LinearProgress /> : null}
              {results.length ? (
                <List style={{ width: 260 }}>
                  {results.map((result, i) => (
                    <UserListItem
                      to={`/user/${result.userId}`}
                      key={result.userId ?? i}
                      user={result}
                      component={Link}
                      button
                    />
                  ))}
                </List>
              ) : value && !loading ? (
                <Box p={2}>
                  <AccountsNotFound />
                </Box>
              ) : null}
            </Paper>
          </Popper>
        </Box>
      </div>
    </ClickAwayListener>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: 0,
      marginLeft: theme.spacing(2),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 1),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    clearIcon: {
      padding: theme.spacing(0, 1),
      height: "100%",
      position: "absolute",
      right: 0,
      top: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  })
);
