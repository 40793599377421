import clsx from "classnames";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { DatePicker, DatePickerProps } from "@material-ui/pickers/DatePicker";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const format = (d: Moment|null, s: string) => moment(d).format(s);
const isValid = (d: Moment|null) => moment(d).isValid();
const isSameDay = (a: Moment|null, b: Moment|null) => moment(a).isSame(moment(b), "day");
const isWithinInterval = (a: Moment|null, { start, end }: { start: Moment|null, end: Moment|null }) =>
  moment(a).isBetween(start, end);

const CustomElements = (props: DatePickerProps & {
  end: Moment|null,
}) => {
  const classes = useStyles();
  const formatWeekSelectLabel = (date: any, invalidLabel: string) => {
    const value = moment(props.value)
    if (!isValid(value)) return invalidLabel;
    if (!isValid(props.end)) return invalidLabel;
    if (isSameDay(value, props.end)) return format(value, "MMM D");
    return `${format(value, "MMM D")} - ${format(props.end, "MMM D")}`;
  };
  const renderWrappedWeekDay = (date: any, selectedDate: any, dayInCurrentMonth: any) => {
    const start = moment(props.value);
    const end = moment(props.end);
    const dateClone = moment(date).clone();
    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);
    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "D")} </span>
        </IconButton>
      </div>
    );
  };

  return (
    <DatePicker
      labelFunc={formatWeekSelectLabel}
      renderDay={renderWrappedWeekDay}
      {...props}
    />
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    dayWrapper: {
      position: "relative"
    },
    day: {
      width: 36,
      height: 36,
      fontSize: theme.typography.caption.fontSize,
      margin: "0 2px",
      color: "inherit"
    },
    customDayHighlight: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "2px",
      right: "2px",
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: "50%"
    },
    nonCurrentMonthDay: {
      color: theme.palette.text.disabled
    },
    highlightNonCurrentMonthDay: {
      color: "#676767"
    },
    highlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white
    },
    firstHighlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%"
    },
    endHighlight: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%"
    }
  })
);

export default CustomElements;
