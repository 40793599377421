import React, { useEffect } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import { auth } from "firebase/app";
import "firebase/auth";

const id = "firebase-auth-container";
const firebaseUI = require("firebaseui");

export default () => {
  useEffect(() => {
    const ui =
      firebaseUI.auth.AuthUI.getInstance() ||
      new firebaseUI.auth.AuthUI(auth());
      ui.start(`#${id}`, {
        credentialHelper: firebaseUI.auth.CredentialHelper.NONE,
        signInOptions: [auth.EmailAuthProvider.PROVIDER_ID],
        callbacks: {
          signInSuccessWithAuthResult: async ({ user }: any) => {
            console.log(user)
          },
          uiShown: () => {
            
          },
        },
      })
  }, []);
  return (
    <Box py={5}>
      <Toolbar />
      <div id={id} />
    </Box>
  );
};
