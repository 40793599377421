import React, { FC } from 'react'
import CheckIcon from '@material-ui/icons/Check'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import WarningSharpIcon from '@material-ui/icons/WarningSharp'
import { TransactionVerificationStatus } from '@xyo-network/coin-plaid-client/build/lib/graphql.generated'

export const TransactionVerificationStatusIcon: FC<{ status: TransactionVerificationStatus|undefined|null }> = ({ status, ...props }) => {
  switch (status) {
    case TransactionVerificationStatus.Falsified: return <CancelOutlinedIcon {...props} />
    case TransactionVerificationStatus.Undetermined: return <WarningSharpIcon {...props} />
    case TransactionVerificationStatus.Verified: return <CheckIcon {...props} />
    default: return null
  }
}

export default TransactionVerificationStatusIcon