import React, { FC } from "react";
import { env } from "./options"
import {
  getClientUri,
  getClient,
  getHooksUri
} from "@xyo-network/coin-plaid-client/build/lib";
import { ApolloProvider } from "@apollo/react-hooks";
import { auth } from "firebase/app";
import { version } from "../../../package.json"
import "firebase/auth";

export const plaidHooksUri = getHooksUri(env)
export const plaidClientUri = getClientUri(env);
export const plaidClient = getClient(plaidClientUri, async () => {
  return {
    headers: {
      "X-Auth-Token": await getFirebaseAccessToken(),
    }
  };
}, {
  version,
  name: 'cards-web',
});

async function getFirebaseAccessToken () {
  const user = auth().currentUser
  const token = user ? await user.getIdToken() : ''
  return token
}

export const PlaidProvider: FC = ({ children }) => {
  return (
    <ApolloProvider client={plaidClient}>
      {children}
    </ApolloProvider>
  )
}