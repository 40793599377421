import React from 'react'
import Apple from '@material-ui/icons/Apple'
import Android from '@material-ui/icons/Android'

export const DeviceIcon = ({ os }: { os?: string|null }) => {
  switch (os) {
    case 'ios': return <Apple />
    case 'android': return <Android />
  }
  return null
}