import {
  FindContextProvider,
  PaginationProvider,
  useFindContext,
  usePagination,
} from "@elevatejs/material-crud-ui";
import {
  CardUserSubscriptionsDocument,
  InAppSubscription,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { FC, useMemo } from "react";
import { SubscriptionList } from "../../components/Subscriptions/List";
import { ExpandableTable } from "../../components/Table/Expandable";
import {
  useSetUserSubscriptionsExpanded,
  useUserSubscriptionsExpanded,
  useActiveSubscriptionHawk,
  useSetActiveSubscriptionHawk,
  useUserControlsContext
} from "./controls";

export const CardUserSubscriptions = () => {
  return (
    <PaginationProvider initialPerPage={5}>
      <CardUserSubscriptionsProvider>
        <CardUserSubscriptionsComponent />
      </CardUserSubscriptionsProvider>
    </PaginationProvider>
  );
};

export const CardUserSubscriptionsProvider: FC = ({
  children,
}) => {
  const { userId } = useUserControlsContext()
  const expanded = useUserSubscriptionsExpanded();
  const [{ page, perPage }] = usePagination();
  const variables = useMemo(() => ({ userId, page, perPage }), [
    userId,
    page,
    perPage,
  ]);
  const options = useMemo(() => ({ skip: !expanded, notifyOnNetworkStatusChange: true }), [expanded]);
  return (
    <FindContextProvider
      typeName="InAppSubscription"
      variables={variables}
      options={options}
      findDocument={CardUserSubscriptionsDocument}
      selectFindData={(v, data) =>
        data?.cardUserById?.getSubscriptionHistory?.data ?? []
      }
      selectFindTotal={(v, data) =>
        data?.cardUserById?.getSubscriptionHistory?.total ?? 0
      }
    >
      {children}
    </FindContextProvider>
  );
};

export const CardUserSubscriptionsComponent: FC = () => {
  const { data, loading, refetch } = useFindContext<InAppSubscription[]>();
  const activeSubscription = useActiveSubscriptionHawk();
  const setActiveSubscription = useSetActiveSubscriptionHawk();
  const expanded = useUserSubscriptionsExpanded();
  const setExpanded = useSetUserSubscriptionsExpanded();
  return (
    <ExpandableTable
      title="Subscriptions"
      loading={loading}
      expanded={expanded}
      setExpanded={() => setExpanded(!expanded)}
      onRefresh={refetch}
    >
      <SubscriptionList
        activeSubscription={activeSubscription}
        setActiveSubscription={(v) => setActiveSubscription(v)}
        subscriptions={data}
        loading={loading}
      />
    </ExpandableTable>
  );
};
