import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { AtriumTransaction, TransactionVerificationStatus, useSetAtriumTransactionVerificationStatusMutation, useVerifyAtriumTransactionLocationQuery } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { FC } from "react";
import { formatNumber } from "../../utils/number";
import { Map, Marker } from "../Map";
import { BoldBalance } from "../Transactions/Balance";
import TransactionVerificationStatusIcon from "../Transactions/VerificationStatusIcon";

export const AtriumTransactionVerificationDialog: FC<{
  activeTransaction: AtriumTransaction | null;
  setActiveTransaction: (v: AtriumTransaction | null) => any;
}> = ({ activeTransaction, setActiveTransaction }) => {
  const guid = activeTransaction?.guid ?? ''
  const { data, loading } = useVerifyAtriumTransactionLocationQuery({
    variables: { guid },
    skip: !guid,
  });
  const [
    setTransactionVerificationStatus,
    update,
  ] = useSetAtriumTransactionVerificationStatusMutation();
  const verifyAtriumTransactionLocation = data?.verifyAtriumTransactionLocation;
  const account = verifyAtriumTransactionLocation?.account;
  const transaction = verifyAtriumTransactionLocation?.transaction;
  const institution = verifyAtriumTransactionLocation?.institution;
  const near = verifyAtriumTransactionLocation?.near;
  return (
    <Dialog
      open={Boolean(activeTransaction)}
      onClose={() => setActiveTransaction(null)}
      maxWidth="sm"
      fullWidth
    >
      <Box display="flex" alignItems="center" flexDirection="row" p={3} pb={1}>
        {institution?.mediumLogoUrl ? (
          <Avatar src={institution?.mediumLogoUrl} />
        ) : (
          <Skeleton variant="circle" width={40} height={40} />
        )}
        <Box mx={2} flex={1}>
          {loading ? (
            <>
              <Skeleton width={120} height={20} />
              <Skeleton width={120} height={20} />
            </>
          ) : (
            <>
              <Typography variant="body1" color="textSecondary">
                {institution?.name ?? ""}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {account?.name ?? ""}
              </Typography>
            </>
          )}
        </Box>
        {transaction ? (
          <TransactionVerificationStatusIcon
            status={transaction.verification_status}
          />
        ) : null}
      </Box>
      <Box px={3} py={1} display="flex" alignItems="center">
        <ListItemText>
          {loading ? (
            <Skeleton width={120} height={20} />
          ) : (
            <Typography color="textSecondary">
              {transaction?.description ?? ""}
            </Typography>
          )}
        </ListItemText>
        {loading ? (
          <Skeleton width={60} height={20} />
        ) : (
          <BoldBalance value={transaction?.amount ?? 0} />
        )}
      </Box>
      {loading && activeTransaction?.longitude && activeTransaction?.latitude ? (
        <DialogContent>
          <Skeleton variant="rect" width="100%" height={300} />
        </DialogContent>
      ) : activeTransaction?.longitude && activeTransaction?.latitude ? (
        <DialogContent>
          <Map
            options={{
              center: [activeTransaction?.longitude, activeTransaction?.latitude],
              zoom: 16,
            }}
            style={{ height: 300, width: "100%" }}
          >
            <Marker
              className="marker"
              coordinates={[activeTransaction?.longitude, activeTransaction?.latitude]}
            >
              {`$${formatNumber(transaction?.amount ?? 0)}`}
            </Marker>
          </Map>
        </DialogContent>
      ) : null}
      <Box p={3} pt={1} display="flex" alignItems="center" flexDirection="row">
        <Box flex={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setActiveTransaction(null)}
            disabled={loading}
          >
            Close
          </Button>
        </Box>
        <Box mx={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              setTransactionVerificationStatus({
                variables: {
                  guid,
                  verification_status: TransactionVerificationStatus.Falsified,
                },
              })
            }
            disabled={loading || update.loading}
          >
            Reject
          </Button>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            setTransactionVerificationStatus({
              variables: {
                guid,
                verification_status: TransactionVerificationStatus.Verified,
              },
            })
          }
          disabled={loading || update.loading}
        >
          Verify
        </Button>
      </Box>
    </Dialog>
  );
};

export default AtriumTransactionVerificationDialog;
