import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {
  InAppSubscriptionError
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";

interface SubscriptionErrorListItemProps {
  active?: boolean;
  subscriptionError: InAppSubscriptionError;
  onClick?: () => any;
}

export const SubscriptionErrorListItem = ({
  active,
  subscriptionError,
  onClick,
}: SubscriptionErrorListItemProps) => {
  return (
    <ListItem onClick={onClick} selected={active} button>
      <ListItemAvatar>
        <Avatar>
          {subscriptionError?.responseCode ?? ""}
          {/* <TransactionVerificationStatusIcon
            status={transaction.verification_status}
          /> */}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={subscriptionError?.message ?? ""}
        primaryTypographyProps={{ noWrap: true }}
        secondary={subscriptionError?.code ?? ""}
        secondaryTypographyProps={{ noWrap: true }}
      ></ListItemText>
      {/* <BoldBalance value={transaction.amount} /> */}
    </ListItem>
  );
};

export default SubscriptionErrorListItem;
