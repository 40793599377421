import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import BuildIcon from "@material-ui/icons/Build";
import React, { FC } from "react";

export const UnderConstruction: FC = ({ children }) => {
  return (
    <Box position="relative">
      <Backdrop style={{ zIndex: 1, position: "absolute" }} open={true}>
        <Paper>
          <Box textAlign="center" p={4}>
            <BuildIcon fontSize="large" />
            <Typography variant="h5">Under Construction</Typography>
          </Box>
        </Paper>
      </Backdrop>
      {children}
    </Box>
  );
};
