import {
  FindContextProvider,
  PaginationProvider,
  useFindContext,
  usePagination,
} from "@elevatejs/material-crud-ui";
import {
  AtriumTransaction,
  CardUserAtriumTransactionsDocument,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { FC, useMemo } from "react";
import { AtriumTransactionList } from "../../components/AtriumTransactions/List";
import { ExpandableTable } from "../../components/Table/Expandable";
import {
  useSetUserAtriumTransactionsExpanded,
  useSetVerifyAtriumTransactionHawk,
  useUserAtriumTransactionsExpanded,
  useUserControlsContext,
  useVerifyAtriumTransactionHawk,
} from "./controls";

export const CardUserAtriumTransactions = () => {
  return (
    <PaginationProvider initialPerPage={5}>
      <CardUserTransactionsProvider>
        <CardUserTransactionsComponent />
      </CardUserTransactionsProvider>
    </PaginationProvider>
  );
};

export const CardUserTransactionsProvider: FC = ({ children }) => {
  const { userId } = useUserControlsContext();
  const [{ page, perPage }] = usePagination();
  const expanded = useUserAtriumTransactionsExpanded();
  const variables = useMemo(() => ({ userId, pagination: { page, perPage } }), [
    userId,
    page,
    perPage,
  ]);
  const options = useMemo(() => ({ skip: !expanded, notifyOnNetworkStatusChange: true }), [expanded]);
  return (
    <FindContextProvider
      typeName="Transaction (MX)"
      variables={variables}
      options={options}
      findDocument={CardUserAtriumTransactionsDocument}
      selectFindData={(v, data) =>
        data?.cardUserById?.atriumUser?.cachedTransactions?.data ?? []
      }
      selectFindTotal={(v, data) =>
        data?.cardUserById?.atriumUser?.cachedTransactions?.total ?? 0
      }
    >
      {children}
    </FindContextProvider>
  );
};

export const CardUserTransactionsComponent: FC = () => {
  const { data, loading, refetch } = useFindContext<AtriumTransaction[]>();
  const expanded = useUserAtriumTransactionsExpanded();
  const setExpanded = useSetUserAtriumTransactionsExpanded();
  const verifyTransaction = useVerifyAtriumTransactionHawk();
  const setVerifyTransaction = useSetVerifyAtriumTransactionHawk();
  return (
    <ExpandableTable
      title="Transactions (MX)"
      loading={loading}
      expanded={expanded}
      setExpanded={() => setExpanded(!expanded)}
      onRefresh={refetch}
    >
      <AtriumTransactionList
        activeTransaction={verifyTransaction}
        setActiveTransaction={(v) => setVerifyTransaction(v)}
        transactions={data}
        loading={loading}
      />
    </ExpandableTable>
  );
};
