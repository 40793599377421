import React, { FC, ReactNode } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export const AccordionCode: FC<{ title: ReactNode; id: string | number }> = ({
  title,
  children,
  id,
}) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          p={2}
          width="100%"
          style={{
            backgroundColor: "#000",
            overflow: "scroll",
            WebkitOverflowScrolling: "touch",
          }}
        >
          {children}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};