import React, { useState } from "react";
import { contextControllerHOC } from "../../utils/controls";

export const LayoutControls = contextControllerHOC(useBuildLayoutControls);

export function useBuildLayoutControls() {
  const [loading, setLoading] = useState(false);
  return {
    loading,
    setLoading,
  };
}