import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import FilterIcon from "@material-ui/icons/FilterList";
import CardActions from "@material-ui/core/CardActions";
import TablePagination from "@material-ui/core/TablePagination";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";
import Avatar from "@material-ui/core/Avatar";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";
import ChangeIcon from "@material-ui/icons/ChangeHistory";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import React, { FC, useState } from "react";
import { AccountsNotFound } from "../../components/Accounts";
import { makeStyles } from "@material-ui/core/styles";
import { useIsMdUp } from "../../components/Layout/utils";
import { TransactionControls } from "./controls";
import times from "lodash/times";
import TransactionVerificationStatusIcon from "../../components/Transactions/VerificationStatusIcon";
import { Typography } from "@material-ui/core";

export const TransactionHistory: FC = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMdUp = useIsMdUp();

  const {
    activity,
    activityLoading,
    activityTotal,
    activityPage,
    activityPerPage,
    setActivityPage,
    setActivityPerPage,
  } = TransactionControls.useControls();

  const handlePageChange = (event: any, page: number) => {
    setActivityPage(page);
  };

  const handleRowsPerPageChange = (event: any) => {
    setActivityPerPage(event.target.value);
  };

  return (
    <Card>
      <CardHeader
        title="History"
        action={
          <Fab
            size="small"
            onClick={(ev: any) => setAnchorEl(ev.target)}
            color="primary"
          >
            <FilterIcon />
          </Fab>
        }
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {/* <InstitutionsList
          institutions={institutions}
          selected={selectedInstitutions}
          toggleSelected={toggleInstitutions}
          setSelected={setSelectedInstitution}
        /> */}
      </Menu>
      <Divider />
      <CardContent
        className="scroll-md"
        // style={
        //   isMdUp
        //     ? { height: "calc(100vh - 434px)" }
        //     : { minHeight: "calc(100vh - 434px)" }
        // }
      >
        <ActivityList />
      </CardContent>
      <CardActions style={{ justifyContent: "flex-end" }}>
        <TablePagination
          component="div"
          count={activityTotal || 0}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={activityPage}
          rowsPerPage={activityPerPage}
          rowsPerPageOptions={[5]}
        />
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
    overflow: "hidden",
  },
  avatar: {
    width: 30,
    height: 30,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const ActivityList = () => {
  const {
    activity,
    activityLoading,
    activityPerPage,
  } = TransactionControls.useControls();
  const classes = useStyles();
  const rows = times(activityPerPage).map((i) => {
    return (
      <ListItem key={i}>
        <ListItemText
          primary={
            activityLoading ? (
              <Skeleton
                variant="rect"
                height={18}
                width={180}
                style={{ marginBottom: 4 }}
              />
            ) : activity[i]?.type ? (
              activity[i]?.type ?? ""
            ) : <span>&nbsp;</span>
          }
          primaryTypographyProps={{ noWrap: true }}
          secondary={
            activityLoading ? (
              <Skeleton variant="rect" height={18} width={120} />
            ) : activity[i]?.created_date ? (
              new Date(activity[i]?.created_date).toLocaleString() ?? ""
            ) : (
              <span>&nbsp;</span>
            )
          }
          secondaryTypographyProps={{ noWrap: true }}
        ></ListItemText>

        {activityLoading ? (
          <Skeleton variant="circle" className={classes.avatar} />
        ) : activity[i]?.type === 'Set Verification Status' ? (
          <Box display="flex" alignItems="center">
            {activity[i]?.verification_status?.previous ?
            <><Avatar className={classes.avatar}>
              <TransactionVerificationStatusIcon status={activity[i]?.verification_status?.previous as any} />
            </Avatar>
            <Box style={{
              height: 4,
              width: 10,
              marginLeft: 2,
              backgroundColor: '#757575'
            }}></Box>
            <Box style={{
              width: 0,
              height: 0,
              marginRight: 2,
              borderTop: '8px solid transparent',
              borderBottom: '8px solid transparent',
              borderLeft: '8px solid #757575',
            }}></Box>
            </> : null}
            <Avatar className={classes.avatar}>
              <TransactionVerificationStatusIcon status={activity[i]?.verification_status?.current as any} />
            </Avatar>
          </Box>
        ) : activity[i]?.type ? (
          <Avatar className={classes.avatar}>
            <TransactionActivityIcon type={activity[i]?.type} />
          </Avatar>
        ) : (
          <Box className={classes.avatar}></Box>
        )}
      </ListItem>
    );
  });

  return (
    <List dense disablePadding>
      {rows}
    </List>
  );
};

const TransactionActivityIcon = ({ type }: { type?: string | null }) => {
  switch (type) {
    case "Created":
      return <AddIcon />;
    case "Confirmed":
      return <CheckIcon />;
    case "Deleted":
      return <CancelIcon />;
    case "Set Verification Status":
      return <ChangeIcon />;
    case "Notification Sent":
      return <NotificationsIcon />;
    case "Notification Error":
      return <NotificationsOffIcon />;
    default:
      return null;
  }
};
