import {
  FindContextProvider,
  PaginationProvider,
  useFindContext,
  usePagination,
} from "@elevatejs/material-crud-ui";
import {
  CardUserSubscriptionsAndroidS2SDocument,
  AndroidInAppSubscriptionS2S,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { FC, useMemo } from "react";
import { SubscriptionList } from "../../components/SubscriptionsS2SAndroid/List";
import { ExpandableTable } from "../../components/Table/Expandable";
import {
  useSetUserSubscriptionsS2SAndroidExpanded,
  useUserSubscriptionsS2SAndroidExpanded,
  useActiveSubscriptionS2SAndroidHawk,
  useSetActiveSubscriptionS2SAndroidHawk,
  useUserControlsContext
} from "./controls";

export const SubscriptionsS2SAndroid = () => {
  return (
    <PaginationProvider initialPerPage={5}>
      <SubscriptionsS2SAndroidProvider>
        <SubscriptionsS2SAndroidComponent />
      </SubscriptionsS2SAndroidProvider>
    </PaginationProvider>
  );
};

export const SubscriptionsS2SAndroidProvider: FC = ({
  children,
}) => {
  const { userId } = useUserControlsContext()
  const expanded = useUserSubscriptionsS2SAndroidExpanded();
  const [{ page, perPage }] = usePagination();
  const variables = useMemo(() => ({ userId, page, perPage }), [
    userId,
    page,
    perPage,
  ]);
  const options = useMemo(() => ({ skip: !expanded, notifyOnNetworkStatusChange: true }), [expanded]);
  return (
    <FindContextProvider
      typeName="AndroidInAppSubscriptionS2S"
      variables={variables}
      options={options}
      findDocument={CardUserSubscriptionsAndroidS2SDocument}
      selectFindData={(v, data) =>
        data?.cardUserById?.getSubscriptionAndroidS2SHistory?.data ?? []
      }
      selectFindTotal={(v, data) =>
        data?.cardUserById?.getSubscriptionAndroidS2SHistory?.total ?? 0
      }
    >
      {children}
    </FindContextProvider>
  );
};

export const SubscriptionsS2SAndroidComponent: FC = () => {
  const { data, loading, refetch } = useFindContext<AndroidInAppSubscriptionS2S[]>();
  const activeSubscription = useActiveSubscriptionS2SAndroidHawk();
  const setActiveSubscription = useSetActiveSubscriptionS2SAndroidHawk();
  const expanded = useUserSubscriptionsS2SAndroidExpanded();
  const setExpanded = useSetUserSubscriptionsS2SAndroidExpanded();
  return (
    <ExpandableTable
      title="Subscriptions S2S Android"
      loading={loading}
      expanded={expanded}
      setExpanded={() => setExpanded(!expanded)}
      onRefresh={refetch}
    >
      <SubscriptionList
        activeSubscription={activeSubscription}
        setActiveSubscription={(v) => setActiveSubscription(v)}
        subscriptions={data}
        loading={loading}
      />
    </ExpandableTable>
  );
};
