import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {
  AtriumWebhookExecution,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";
import WebhookExecutionStatusIcon from "./WebhookExecutionStatusIcon";

interface WebhookExecutionListItemProps {
  active?: boolean;
  execution: AtriumWebhookExecution;
  onClick?: () => any;
}

export const WebhookExecutionListItem = ({
  active,
  execution,
  onClick,
}: WebhookExecutionListItemProps) => {
  return (
    <ListItem onClick={onClick} selected={active} button>
      <ListItemAvatar>
        <Avatar>
          <WebhookExecutionStatusIcon
            type={execution.type}
          />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={execution.type ?? ""}
        primaryTypographyProps={{ noWrap: true }}
        secondary={new Date(execution.completedAt).toLocaleString() ?? ""}
        secondaryTypographyProps={{ noWrap: true }}
      ></ListItemText>
      <Typography variant="body2">
        {execution.connectionStatus ?? execution.transactionsCreatedCount}
      </Typography>
    </ListItem>
  );
};

export default WebhookExecutionListItem;
