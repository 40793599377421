import {
  FindContextProvider,
  PaginationProvider,
  useFindContext,
  usePagination,
} from "@elevatejs/material-crud-ui";
import {
  AtriumWebhookExecution,
  CardUserWebhookExecutionsDocument,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { FC, useMemo } from "react";
import { ExpandableTable } from "../../components/Table/Expandable";
import { AtriumWebhookList } from "../../components/WebhookExecutions/AtriumWebhookList";
import {
  useUserControlsContext,
  useSetUserWebhooksExpanded,
  useUserWebhooksExpanded,
} from "./controls";

export const CardUserAtriumWebhooks = () => {
  return (
    <PaginationProvider initialPerPage={5}>
      <CardUserAtriumWebhooksProvider>
        <CardUserAtriumWebhooksComponent />
      </CardUserAtriumWebhooksProvider>
    </PaginationProvider>
  );
};

const selectFindData = (v: string, data: any) =>
  data?.cardUserById?.atriumUser?.webhookExecutions?.data ?? [];
const selectFindTotal = (v: string, data: any) =>
  data?.cardUserById?.atriumUser?.webhookExecutions?.total ?? 0;

export const CardUserAtriumWebhooksProvider: FC = ({ children }) => {
  const { userId } = useUserControlsContext();
  const expanded = useUserWebhooksExpanded();
  const [{ page, perPage }] = usePagination();
  const variables = useMemo(() => ({ userId, page, perPage }), [
    userId,
    page,
    perPage,
  ]);
  const options = useMemo(() => ({ skip: !expanded, notifyOnNetworkStatusChange: true }), [expanded]);
  return (
    <FindContextProvider
      typeName="AtriumWebhookExecution"
      variables={variables}
      options={options}
      findDocument={CardUserWebhookExecutionsDocument}
      selectFindData={selectFindData}
      selectFindTotal={selectFindTotal}
    >
      {children}
    </FindContextProvider>
  );
};

export const CardUserAtriumWebhooksComponent: FC = () => {
  const { data, loading, refetch } = useFindContext<AtriumWebhookExecution[]>();
  const expanded = useUserWebhooksExpanded();
  const setExpanded = useSetUserWebhooksExpanded();
  return (
    <ExpandableTable
      title="Webhooks (MX)"
      loading={loading}
      expanded={expanded}
      setExpanded={() => setExpanded(!expanded)}
      onRefresh={refetch}
    >
      <AtriumWebhookList webhookExecutions={data || []} loading={loading} />
    </ExpandableTable>
  );
};
