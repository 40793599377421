import React, { FC, useState, useMemo, useEffect } from "react";
import { contextControllerHOC, useUrlState } from "../../utils/controls";
import {
  useVerifiableTransactionsPageAdminQuery,
  useVerifiableTransactionsPageAdminLazyQuery,
  PlaidTransaction,
  VerifiableTransactionsFilter,
  TransactionVerificationStatus,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import isString from "lodash/isString";
import keyBy from "lodash/keyBy";
import get from "lodash/get";
import { LayoutControls } from "../../components/Layout";
import { PaginationControls } from "../../components/Table/Pagination";
import { SelectManyControls } from "../../components/Table/Select";

export type IVerificationsFilterControls = ReturnType<
  typeof useBuildVerificationsControls
>;

export const VerificationsControls = contextControllerHOC(
  useBuildVerificationsControls
);

export const VerificationsControlsProvider: FC = ({ children }) => {
  return (
    <PaginationControls.Provider perPage={5}>
      <SelectManyControls.Provider>
        <VerificationsControls.Provider>
          {children}
        </VerificationsControls.Provider>
      </SelectManyControls.Provider>
    </PaginationControls.Provider>
  );
};

function useBuildVerificationsControls() {
  const layoutControls = LayoutControls.useControls()
  const paginationControls = PaginationControls.useControls();
  const [
    verificationStatus,
    setVerificationStatus,
  ] = useUrlState('status', []);
  const [
    activeTransaction,
    setActiveTransaction,
  ] = useState<PlaidTransaction | null>(null);
  const variables = useMemo(() => {
    const filter: VerifiableTransactionsFilter = {};
    if (verificationStatus.length) {
      filter.verification_status = {
        IN: ([] as any[]).concat(verificationStatus),
      };
    }
    return {
      pagination: paginationControls.variables,
      filter,
    };
  }, [paginationControls.variables, verificationStatus]);
  const { data, loading, error } = useVerifiableTransactionsPageAdminQuery({
    notifyOnNetworkStatusChange: true,
    variables
  });
  const verifiableTransactionsPageAdmin = data?.verifiableTransactionsPageAdmin;
  const transactions = verifiableTransactionsPageAdmin?.transactions ?? [];
  const institutions = verifiableTransactionsPageAdmin?.institutions ?? [];
  const cardUsers = verifiableTransactionsPageAdmin?.cardUsers ?? [];
  const accounts = verifiableTransactionsPageAdmin?.accounts ?? [];
  const total = verifiableTransactionsPageAdmin?.total_transactions ?? 0;
  const transactionIds = transactions
    .map((transaction) => transaction?.transaction_id)
    .filter(isString);
  const institutionsById = keyBy(institutions, "institution_id");
  const accountsById = keyBy(accounts, "account_id");
  const cardUsersById = keyBy(cardUsers, "userId");
  const getInstitutionByTransaction = (transaction: PlaidTransaction) => {
    const account = accountsById[transaction.account_id || ""];
    const institution_id = get(account, "institution_id") || "";
    const institution = institutionsById[institution_id];
    return institution;
  };
  const getUserByTransaction = (transaction: PlaidTransaction) =>
    cardUsersById[transaction.userId || ""];

  useEffect(() => {
    layoutControls.setLoading(loading)
  }, [loading])

  return {
    loading,
    error,
    activeTransaction,
    setActiveTransaction,
    verificationStatus,
    setVerificationStatus,
    transactions,
    institutions,
    cardUsers,
    accounts,
    total,
    transactionIds,
    institutionsById,
    accountsById,
    cardUsersById,
    getInstitutionByTransaction,
    getUserByTransaction,
  };
}