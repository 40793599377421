import { IosInAppSubscriptionS2S } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";
import { TableList } from "../Table/List";
import SubscriptionListItem from "./ListItem";
import SubscriptionsNotFound from "./NotFound";

interface SubscriptionListProps {
  loading?: boolean;
  activeSubscription?: IosInAppSubscriptionS2S | null;
  setActiveSubscription?: (v: IosInAppSubscriptionS2S | null) => any;
  subscriptions?: (IosInAppSubscriptionS2S | null)[];
}

export const SubscriptionList = ({
  loading,
  subscriptions = [],
  activeSubscription,
  setActiveSubscription,
}: SubscriptionListProps) => {
  return (
    <TableList
      loading={loading}
      items={subscriptions}
      getItemId={(subscription) => subscription._id}
      renderItem={(subscription) => (
        <SubscriptionListItem
          subscription={subscription}
          active={subscription === activeSubscription}
          onClick={
            setActiveSubscription
              ? () =>
                  setActiveSubscription(
                    subscription === activeSubscription ? null : subscription
                  )
              : undefined
          }
        />
      )}
    >
      <SubscriptionsNotFound />
    </TableList>
  );
};
