import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useIsMdUp } from "../../components/Layout/utils";
// import { TransactionVerificationDialog } from "../../components/Transactions/Verification";
import { TransactionControls } from "./controls";
import { TransactionDetails } from "./Details";
import { TransactionLocations } from "./Locations";
import { TransactionHistory } from "./History";

export const Transaction = ({ transactionId }: { transactionId: string }) => {
  const isMdUp = useIsMdUp();
  return (
    <TransactionControls.Provider transactionId={transactionId}>
      {/* {loading ? <LinearProgress variant="indeterminate" /> : null} */}
      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <TransactionDetails />
            {isMdUp ? <Box pt={3}>
              <TransactionHistory />
            </Box> : null}
          </Grid>
          <Grid item md={8} xs={12}>
            <Box height="100%">
              <TransactionLocations />
            </Box>
          </Grid>
          {isMdUp ? null : <Grid item xs={12}>
            <TransactionHistory />
          </Grid>}
        </Grid>
      </Box>
    </TransactionControls.Provider>
  );
}

export default () => {
  const match = useRouteMatch<{ transactionId: string }>();
  return <Transaction transactionId={match.params.transactionId} /> 
};
