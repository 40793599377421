import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import React, { FC, useState } from "react";
import Logout from "./Logout";
import Navbar from "./Navbar";
import Search from "./Search";
import Sidebar from "./Sidebar";
import Profile from "../Profile";
import Footer from "./Footer";
import { useIsSmUp, useIsLgUp } from "./utils";
import { LayoutControls } from "./controls";
import { useUser } from "../../providers/Auth";
import "firebase/auth";

export * from "./controls"

export const Layout: FC = ({ children }) => {
  const user = useUser();
  const [menu, setMenu] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const isSmUp = useIsSmUp();
  const isLgUp = useIsLgUp();

  return (
    <LayoutControls.Provider>
      <Box
        position="relative"
        style={{
          paddingLeft: isLgUp ? 260 : 0,
        }}
      >
        <Navbar
          menu={
            !isLgUp ? (
              <Box mr={1}>
                <IconButton onClick={() => setMenu(!menu)}>
                  <MenuIcon />
                </IconButton>
              </Box>
            ) : null
          }
        >
          <Search />
        </Navbar>
        <Drawer
          anchor="left"
          open={isLgUp || menu}
          onClose={() => setMenu(false)}
          variant={isLgUp ? "persistent" : "temporary"}
        >
          <Box style={{ minWidth: 260, paddingTop: isSmUp ? 64 : 50 }}>
            <Box p={2}>
              {/* <Profile user={user} />
              <Box my={2}>
                <Divider />
              </Box> */}
              <List>
                <Sidebar onLogout={() => setConfirm(true)} />
              </List>
            </Box>
          </Box>
        </Drawer>
        <Box
          style={{
            paddingTop: isSmUp ? 64 : 50,
          }}
        >
          {children}
          {/* <Footer /> */}
        </Box>
      </Box>
      <Logout open={confirm} onClose={() => setConfirm(false)} />
    </LayoutControls.Provider>
  );
};

export const AuthLayout: FC = ({ children }) => {
  return (
    <LayoutControls.Provider>
      <Navbar />
      {children}
    </LayoutControls.Provider>
  );
};

export default Layout;
