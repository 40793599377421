import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React, { useState } from "react";
import Profile from "../../components/Profile";
import { LoadingButton } from "../../components/Button";
import {
  cacheUserTransactions,
  diffUserTransactions,
  useUserControlsContext,
} from "./controls";
import { useCardUserQuery } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import { useFlasher } from "../../utils/flasher";
import Typography from "@material-ui/core/Typography";

export const CardUserProfile = () => {
  const ref = useFlasher();
  const { userId } = useUserControlsContext();
  const { data, loading, error } = useCardUserQuery({ variables: { userId }, errorPolicy: "ignore" });
  const [modalData, setModalData] = useState<any>(null);
  const user = data?.cardUserById;
  return (
    <div ref={ref}>
      <Card>
        <CardContent>
          <Profile loading={loading} user={user} />
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          <Typography color="error">{error?.message}</Typography>
          <LoadingButton
            color="secondary"
            variant="contained"
            disabled={!user}
            fn={() => diffUserTransactions(user?.userId)}
            onSuccess={(data: any) => setModalData(data)}
            onError={(data: any) => alert(data.message)}
          >
            Diff
          </LoadingButton>
          <Box ml={1}>
            <LoadingButton
              color="primary"
              variant="contained"
              disabled={!user}
              fn={() => cacheUserTransactions(user?.userId)}
              onSuccess={(data: any) => setModalData(data)}
              onError={(data: any) => alert(data.message)}
            >
              Cache
            </LoadingButton>
          </Box>
        </CardActions>
        <Dialog
          open={Boolean(modalData)}
          onClose={() => setModalData(null)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle title="Result" />
          <DialogContent>
            <Box
              style={{
                backgroundColor: "#000",
                WebkitOverflowScrolling: "touch",
              }}
              p={1}
              maxWidth="100%"
              maxHeight="100%"
              overflow="auto"
            >
              <pre>{JSON.stringify(modalData, null, 2)}</pre>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-end" }}>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setModalData(null)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </div>
  );
};
