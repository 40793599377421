import { useGetCachedTransactionsQuery } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import get from "lodash/fp/get";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import { hasLocationLatLon } from "../../utils/location";

const getAccessTokens = get("getAccessTokens");
const getCachedAccounts = get("getCachedTransactions.accounts");
const getCachedTransactions = get("getCachedTransactions.transactions");

export const useCachedTransactionsByAccount = (opts = {}) => {
  const { data, loading, error, refetch } = useGetCachedTransactionsQuery({ ...opts, notifyOnNetworkStatusChange: true })
  const accounts = getCachedAccounts(data) || []
  const transactions = getCachedTransactions(data) || []
  const accountTransactions = groupBy(transactions, "account_id");
  const locationTransactions = transactions.filter(hasLocationLatLon)
  const tokens = getAccessTokens(data) || [];
  const institutions = map(tokens, "institution");
  const institutionsById = keyBy(institutions, "institution_id");
  return {
    refetch,
    loading,
    error,
    tokens,
    accounts,
    transactions,
    institutions,
    institutionsById,
    locationTransactions,
    accountTransactions
  }
}