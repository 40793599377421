import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  AtriumTransaction,
  TransactionVerificationStatus
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import Table from "../../components/Table";
import { PaginationControls } from "../../components/Table/Pagination";
import { CheckboxCell, ToggleSelected } from "../../components/Table/Select";
import { AtriumTransactionVerificationDialog } from "../../components/AtriumTransactions/Verification";
import { BoldBalance } from "../../components/Transactions/Balance";
import TransactionVerificationStatusIcon from "../../components/Transactions/VerificationStatusIcon";
import { VerificationsControls } from "./controls";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

export default () => {
  const {
    activeTransaction,
    setActiveTransaction,
    total,
  } = VerificationsControls.useControls();

  return (
    <>
      <Box p={3}>
        <Table
          title="Verifications"
          total={total}
          filters={<Filters />}
          head={<Head />}
          body={<Body />}
        ></Table>
      </Box>
      <AtriumTransactionVerificationDialog
        activeTransaction={activeTransaction}
        setActiveTransaction={(v) => setActiveTransaction(v)}
      />
    </>
  );
};

const Filters = () => {
  const {
    verificationStatus,
    setVerificationStatus,
  } = VerificationsControls.useControls();
  const { setPage } = PaginationControls.useControls();
  return (
    <Box p={1} minWidth={240} maxWidth={240}>
      <Box mb={1}>
        <InputLabel id="verification-status">Status</InputLabel>
        <Select
          id="verification-status"
          value={([] as any[]).concat(verificationStatus)}
          onChange={(ev: any) => {
            setPage(0);
            setVerificationStatus(ev.target.value);
          }}
          fullWidth
          multiple
        >
          <MenuItem value={TransactionVerificationStatus.Falsified}>
            Falsified
          </MenuItem>
          <MenuItem value={TransactionVerificationStatus.Undetermined}>
            Undetermined
          </MenuItem>
          <MenuItem value={TransactionVerificationStatus.Verified}>
            Verified
          </MenuItem>
        </Select>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField margin="normal" label="Min amount" fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField margin="normal" label="Max amount" fullWidth />
        </Grid>
      </Grid>
    </Box>
  );
};

const Head = () => {
  const { transactionIds } = VerificationsControls.useControls();
  return (
    <TableRow>
      <TableCell padding="checkbox">
        <ToggleSelected all={transactionIds} />
      </TableCell>
      <TableCell>Status</TableCell>
      <TableCell>Institution</TableCell>
      <TableCell>Name</TableCell>
      <TableCell>Amount</TableCell>
      <TableCell>Date</TableCell>
      <TableCell>User</TableCell>
    </TableRow>
  );
};

const Body = () => {
  const history = useHistory();
  const { transactions } = VerificationsControls.useControls();
  return (
    <>
      {(transactions || []).map(
        (transaction) =>
          transaction?.guid && (
            <TableRow
              hover
              key={transaction.guid}
              // selected={
              //   selected.indexOf(transaction.transaction_id) !== -1
              // }
              // onClick={() => setActiveTransaction(transaction)}
              onClick={() =>
                history.push(`/transaction/atrium/${transaction.guid}`)
              }
              style={{ cursor: "pointer" }}
            >
              <TableCell padding="checkbox">
                <CheckboxCell id={transaction.guid} />
              </TableCell>
              <TableCell>
                <TransactionVerificationStatusIcon
                  status={transaction.verification_status}
                />
              </TableCell>
              <TableCell>
                <InstitutionCell transaction={transaction} />
              </TableCell>
              <TableCell>
                <Typography>{transaction.description}</Typography>
              </TableCell>
              <TableCell>
                <BoldBalance value={transaction.amount} />
              </TableCell>
              <TableCell>
                <Typography>
                  {moment(transaction.transactedAt).format("MM/DD/YYYY")}
                </Typography>
              </TableCell>
              <TableCell
                onClick={() => history.push(`/user/${transaction.userId}`)}
              >
                <UserCell transaction={transaction} />
              </TableCell>
            </TableRow>
          )
      )}
    </>
  );
};

const InstitutionCell = ({
  transaction,
}: {
  transaction: AtriumTransaction;
}) => {
  const classes = useStyles();
  const { getInstitutionByTransaction } = VerificationsControls.useControls();
  return (
    <div className={classes.nameContainer}>
      <Avatar
        className={classes.avatar}
        src={
          getInstitutionByTransaction(transaction)?.mediumLogoUrl
            ? getInstitutionByTransaction(transaction)?.mediumLogoUrl ?? undefined
            : undefined
        }
      >
        {getInitials(getInstitutionByTransaction(transaction)?.name)}
      </Avatar>
      <Typography variant="body1">
        {getInstitutionByTransaction(transaction)?.name}
      </Typography>
    </div>
  );
};

const UserCell = ({ transaction }: { transaction: AtriumTransaction }) => {
  const { getUserByTransaction } = VerificationsControls.useControls();
  const classes = useStyles();
  return (
    <div className={classes.nameContainer}>
      <Avatar
        className={classes.avatar}
        src={getUserByTransaction(transaction)?.photoURL ?? undefined}
      >
        {getInitials(getUserByTransaction(transaction)?.email)}
      </Avatar>
      <Typography variant="body1">
        {getUserByTransaction(transaction)?.email}
      </Typography>
    </div>
  );
};

const getInitials = (name: string | null = "") =>
  (name || "")
    .replace(/\s+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");
