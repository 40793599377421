import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { AuthProvider, appOptions } from "./providers/Auth";
import { PlaidProvider } from "./providers/Plaid";
import { initializeApp } from "firebase/app";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./styles";
import App from "./App";
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import MomentUtils from "@date-io/moment";
import "./index.css";

initializeApp(appOptions);

ReactDOM.render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
      <CssBaseline />
      <AuthProvider>
        <PlaidProvider>
          <App />
        </PlaidProvider>
      </AuthProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
