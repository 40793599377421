import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {
  InAppSubscription
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";

interface SubscriptionListItemProps {
  active?: boolean;
  subscription: InAppSubscription;
  onClick?: () => any;
}

export const SubscriptionListItem = ({
  active,
  subscription,
  onClick,
}: SubscriptionListItemProps) => {
  return (
    <ListItem onClick={onClick} selected={active} button>
      <ListItemAvatar>
        <Avatar>
          {/* <TransactionVerificationStatusIcon
            status={transaction.verification_status}
          /> */}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={subscription?.productId ?? ""}
        primaryTypographyProps={{ noWrap: true }}
        secondary={new Date(subscription?.transactionDate).toLocaleString() ?? ""}
        secondaryTypographyProps={{ noWrap: true }}
      ></ListItemText>
      {/* <BoldBalance value={transaction.amount} /> */}
    </ListItem>
  );
};

export default SubscriptionListItem;
