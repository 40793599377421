import React from "react";
import { Switch, Route } from "react-router-dom";
import { Dashboard, Auth, Map, Users, User, Verifications, Transaction, Notifications, AtriumVerifications, AtriumTransaction } from "./routes";
import Layout, { AuthLayout } from "./components/Layout";
import { useAuth } from "./providers/Auth";
import { LinearProgress } from "@material-ui/core";

function App() {
  const { initialized, user } = useAuth();

  if (!initialized) {
    return <LinearProgress variant="indeterminate" />;
  }

  if (!user) {
    return (
      <Switch>
        <Route path="/">
          <AuthLayout>
            <Auth />
          </AuthLayout>
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/" exact>
        <Layout>
          <Dashboard />
        </Layout>
      </Route>
      <Route path="/users" exact>
        <Layout>
          <Users />
        </Layout>
      </Route>
      <Route path="/user/:userId" exact>
        <Layout>
          <User />
        </Layout>
      </Route>
      <Route path="/verifications" exact>
        <Layout>
          <Verifications />
        </Layout>
      </Route>
      <Route path="/verifications/atrium" exact>
        <Layout>
          <AtriumVerifications />
        </Layout>
      </Route>
      <Route path="/transaction/atrium/:guid" exact>
        <Layout>
          <AtriumTransaction />
        </Layout>
      </Route>
      <Route path="/transaction/:transactionId" exact>
        <Layout>
          <Transaction />
        </Layout>
      </Route>
      <Route path="/notifications" exact>
        <Layout>
          <Notifications />
        </Layout>
      </Route>
      <Route path="/map" exact>
        <Layout>
          <Map />
        </Layout>
      </Route>
    </Switch>
  );
}

export default App;
