import { BackgroundLocation } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated"

export interface ILocationLatLon {
  location: {
    lat: number
    lon: number
  }
}

export interface ILatitudeLongitude {
  _id: string
  latitude: number
  longitude: number
}

export function hasLocationLatLon (v: any): v is ILocationLatLon {
  return v && v.location && v.location.lat && v.location.lon
}

export function hasLatitudeLongitude (v: any): v is ILatitudeLongitude {
  return v && v.latitude && v.longitude
}

export function locationToCoords (v: any): [number, number] {
  if (!hasLocationLatLon(v)) return [0, 0]
  return [v.location.lon, v.location.lat]
}

export function backgroundLocationToCoords (v: any): [number, number] {
  if (!hasLatitudeLongitude(v)) return [0, 0]
  return [v.longitude, v.latitude]
}

export function locationPopup(location: BackgroundLocation) {
  return [
    '<div style="color: #333">',
    `<div><b>Time</b> ${new Date(location.date).toLocaleString()}</div>`,
    `<div><b>Platform</b> ${location.platform}</div>`,
    `<div><b>Speed</b> ${location.speed}</div>`,
    `<div><b>Altitude</b> ${location.altitude}</div>`,
    `<div><b>Heading</b> ${location.heading}</div>`,
    `<div><b>Accuracy</b> ${location.accuracy}</div>`,
    `<div><b>YYYYMMDD</b> ${location.yyyymmdd}</div>`,
    `<div><b>YYYYMMDDHH</b> ${location.yyyymmddhh}</div>`,
    `<div><b></b> </div>`,
    "</div>",
  ].join("");
}
