import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { CardUser, useSetUserConfigMutation } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import clsx from "clsx";
import "firebase/auth";
import React, { FC } from "react";
import { getInitials } from "../../utils/string";
import { LoadingSelect } from "../Select";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

export interface ProfileProps {
  className?: string;
  user?: CardUser | null;
  loading?: boolean;
}

export const Profile: FC<ProfileProps> = ({
  user,
  loading,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {loading ? (
        <Skeleton variant="circle" className={classes.avatar} />
      ) : (
        <Avatar
          alt="Person"
          className={classes.avatar}
          src={user?.photoURL ?? undefined}
        >
          {getInitials(user?.displayName ?? user?.email)}
        </Avatar>
      )}
      {loading ? (
        <Skeleton
          variant="rect"
          height={30}
          width={180}
          className={classes.name}
        />
      ) : (
        <Typography
          className={classes.name}
          variant="h5"
          noWrap
          style={{ maxWidth: "100%" }}
        >
          {(user && user.email) || ""}&nbsp;
        </Typography>
      )}
      {loading ? (
        <Skeleton
          variant="rect"
          height={22}
          width={120}
          className={classes.name}
        />
      ) : (
        <Typography variant="body2" noWrap style={{ maxWidth: "100%" }}>
          {user?.config?.activeSubscription ?? ""}{" "}
          {user?.config?.activeSubscriptionInTrial ? "(Trial)" : ""}&nbsp;
        </Typography>
      )}
      <ProfileConfig user={user} loading={loading} />
    </div>
  );
};

export const ProfileConfig: FC<ProfileProps> = ({ loading, user }) => {
  const [updateConfig] = useSetUserConfigMutation()
  const executeUpdate = (key: string, value: any) => {
    updateConfig({
      variables: {
        userId: user?.userId ?? '',
        config: {
          [key]: value
        }
      },
      optimisticResponse: {
        setUserConfig: {
          ...user?.config,
          [key]: value
        }
      }
    })
  }
  
  return (
    <div>
      <FormControl margin="dense" fullWidth>
        <InputLabel htmlFor="plaid-env-select">Plaid Env</InputLabel>
        <LoadingSelect
          loading={loading}
          value={user?.config?.plaidEnv ?? ""}
          id="plaid-env-select"
          onChange={(ev: any) => executeUpdate('plaidEnv', ev.target.value)}
          fullWidth
        >
          <MenuItem value="">Plaid Env</MenuItem>
          <MenuItem value="sandbox">Sandbox</MenuItem>
          <MenuItem value="development">Development</MenuItem>
          <MenuItem value="production">Production</MenuItem>
        </LoadingSelect>
      </FormControl>
      <TextField
        value={user?.config?.maxInstitutions ?? ""}
        onChange={withNumber((n) => executeUpdate('maxInstitutions', n))}
        label="Max Institutions"
        margin="dense"
        helperText="Default value is 3"
        fullWidth
      />
      <TextField
        value={user?.config?.maxTrialInstitutions ?? ""}
        onChange={withNumber((n) => executeUpdate('maxTrialInstitutions', n))}
        label="Max Trial Institutions"
        margin="dense"
        helperText="Default value is 1"
        fullWidth
      />
      <FormControl margin="dense" fullWidth>
      <FormControlLabel
        control={
          <Switch
            disabled={loading}
            checked={user?.config?.mxLink ?? false}
            onChange={(ev) => executeUpdate('mxLink', ev.target.checked)}
            value="mxLink"
            color="primary"
          />
        }
        label="Use MX Link"
      />
      </FormControl>
      <FormControl margin="dense" fullWidth>
      <FormControlLabel
        control={
          <Switch
            disabled={loading}
            checked={user?.config?.plaidLinkUnavailable ?? false}
            onChange={(ev) => executeUpdate('plaidLinkUnavailable', ev.target.checked)}
            value="plaidLinkUnavailable"
            color="primary"
          />
        }
        label="Plaid Link Unavailable"
      />
      </FormControl>
    </div>
  );
};

export default Profile;

function withNumber(fn: (n: number) => any) {
  return (ev: any) => {
    const value = Number(ev.target.value)
    if (!isNaN(value)) fn(value)
  }
}