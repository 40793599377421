import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { PlaidTransaction, TransactionVerificationStatus, useSetTransactionVerificationStatusMutation, useVerifyTransactionLocationQuery } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import get from "lodash/get";
import React, { FC } from "react";
import { hasLocationLatLon, locationToCoords } from "../../utils/location";
import { formatNumber } from "../../utils/number";
import { Map, Marker } from "../Map";
import { BoldBalance } from "./Balance";
import TransactionVerificationStatusIcon from "./VerificationStatusIcon";

export const TransactionVerificationDialog: FC<{
  activeTransaction: PlaidTransaction | null;
  setActiveTransaction: (v: PlaidTransaction | null) => any;
}> = ({ activeTransaction, setActiveTransaction }) => {
  const transaction_id =
    (activeTransaction && activeTransaction.transaction_id) || "";
  const { data, loading } = useVerifyTransactionLocationQuery({
    variables: { transaction_id },
    skip: !transaction_id,
  });
  const [
    setTransactionVerificationStatus,
    update,
  ] = useSetTransactionVerificationStatusMutation();
  const verifyTransactionLocation = data && data.verifyTransactionLocation;
  const account =
    verifyTransactionLocation && verifyTransactionLocation.account;
  const transaction =
    verifyTransactionLocation && verifyTransactionLocation.transaction;
  const institution =
    verifyTransactionLocation && verifyTransactionLocation.institution;
  const near = verifyTransactionLocation && verifyTransactionLocation.near;
  return (
    <Dialog
      open={Boolean(activeTransaction)}
      onClose={() => setActiveTransaction(null)}
      maxWidth="sm"
      fullWidth
    >
      <Box display="flex" alignItems="center" flexDirection="row" p={3} pb={1}>
        {institution && institution.logo ? (
          <Avatar src={`data:image/png;base64,${institution.logo}`} />
        ) : (
          <Skeleton variant="circle" width={40} height={40} />
        )}
        <Box mx={2} flex={1}>
          {loading ? (
            <>
              <Skeleton width={120} height={20} />
              <Skeleton width={120} height={20} />
            </>
          ) : (
            <>
              <Typography variant="body1" color="textSecondary">
                {(institution && institution.name) || ""}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {(account && account.name) || ""}
              </Typography>
            </>
          )}
        </Box>
        {transaction ? (
          <TransactionVerificationStatusIcon
            status={transaction.verification_status}
          />
        ) : null}
      </Box>
      <Box px={3} py={1} display="flex" alignItems="center">
        <ListItemText>
          {loading ? (
            <Skeleton width={120} height={20} />
          ) : (
            <Typography color="textSecondary">
              {(transaction && transaction.name) || ""}
            </Typography>
          )}
        </ListItemText>
        {loading ? (
          <Skeleton width={60} height={20} />
        ) : (
          <BoldBalance value={transaction?.amount ?? 0} />
        )}
      </Box>
      {loading && hasLocationLatLon(activeTransaction) ? (
        <DialogContent>
          <Skeleton variant="rect" width="100%" height={300} />
        </DialogContent>
      ) : hasLocationLatLon(transaction) ? (
        <DialogContent>
          <Map
            options={{
              center: locationToCoords(transaction),
              zoom: 16,
            }}
            style={{ height: 300, width: "100%" }}
          >
            <Marker
              className="marker"
              coordinates={locationToCoords(transaction)}
            >
              {`$${formatNumber(get(transaction, "amount") || 0)}`}
            </Marker>
          </Map>
        </DialogContent>
      ) : null}
      <Box p={3} pt={1} display="flex" alignItems="center" flexDirection="row">
        <Box flex={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setActiveTransaction(null)}
            disabled={loading}
          >
            Close
          </Button>
        </Box>
        <Box mx={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              setTransactionVerificationStatus({
                variables: {
                  transaction_id,
                  verification_status: TransactionVerificationStatus.Falsified,
                },
              })
            }
            disabled={loading || update.loading}
          >
            Reject
          </Button>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            setTransactionVerificationStatus({
              variables: {
                transaction_id,
                verification_status: TransactionVerificationStatus.Verified,
              },
            })
          }
          disabled={loading || update.loading}
        >
          Verify
        </Button>
      </Box>
    </Dialog>
  );
};

export default TransactionVerificationDialog;
