import React, { FC, ReactNode } from "react";
import {
  IosInAppSubscriptionS2S,
  useCardUserSubscriptionsIosS2SByIdQuery,
  IosLatestReceiptInfo,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import TodayIcon from "@material-ui/icons/Today";
import EventIcon from "@material-ui/icons/Event";
import CancelIcon from "@material-ui/icons/Cancel";
import CallMissedIcon from "@material-ui/icons/CallMissed";
import ErrorIcon from "@material-ui/icons/Error";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PaymentIcon from "@material-ui/icons/Payment";
import Avatar from "@material-ui/core/Avatar";
import { DeviceIcon } from "../Devices/Icon";
import { useStyles } from "../Devices/ListItem";
import { LinearProgress, Link } from "@material-ui/core";
import { NotificationTypeIcon } from './NotificationTypeIcon'
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AccordionCode } from "../Code/Accordion";
import last from "lodash/last";

interface SubscriptionDetailsProps {
  subscription: IosInAppSubscriptionS2S;
}

export const SubscriptionS2SIosDetails = ({
  subscription,
}: SubscriptionDetailsProps) => {
  const classes = useStyles();
  const subscriptionsIosS2SByIdQuery = useCardUserSubscriptionsIosS2SByIdQuery({
    skip: !subscription?._id,
    variables: {
      id: subscription?._id,
    },
  });
  console.log(subscription?._id, subscriptionsIosS2SByIdQuery)
  const subscriptionIosS2SHistoryById = subscriptionsIosS2SByIdQuery?.data?.getSubscriptionIosS2SHistoryById;
  return (
    <>
      {subscriptionsIosS2SByIdQuery.loading ? (
        <LinearProgress />
      ) : null}
      <Box p={2}>
        <Box pb={2}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Subscription S2S iOS Details
              </Typography>
              <List>
                <ListItem dense>
                  <ListItemAvatar>
                    <Avatar>
                      <NotificationTypeIcon notification_type={subscription?.notification_type} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={subscription?.notification_type}
                    secondary={new Date(subscription?.createdDate).toLocaleString()}
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Box>
        {subscription ? (
          <Box pb={2}>
            <AccordionCode id="subscription" title="Subscription">
              <pre>{JSON.stringify(subscription, null, 2)}</pre>
            </AccordionCode>
          </Box>
        ) : null}
        {subscriptionIosS2SHistoryById?.latest_receipt_info ? (
          <Box pb={2}>
            <LatestRenewalInfoAccordion id={subscriptionIosS2SHistoryById._id} info={subscriptionIosS2SHistoryById?.latest_receipt_info} />
          </Box>
        ) : null}
        {subscriptionsIosS2SByIdQuery.data ? (
          <Box pb={2}>
            <AccordionCode
              id="verifyReceiptDataAndroid"
              title="Verify Receipt Android"
            >
              <pre>
                {JSON.stringify(subscriptionsIosS2SByIdQuery.data, null, 2)}
              </pre>
            </AccordionCode>
          </Box>
        ) : null}
        {subscriptionsIosS2SByIdQuery.error ? (
          <Box pb={2}>
            <AccordionCode
              id="verifyReceiptErrorAndroid"
              title="Verify Receipt Android Error"
            >
              <pre>
                {JSON.stringify(subscriptionsIosS2SByIdQuery.error, null, 2)}
              </pre>
            </AccordionCode>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

const LatestRenewalInfoAccordion = ({
  info,
  id,
}: {
  id: string | number;
  info?: IosLatestReceiptInfo | null;
}) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <div>
        <Typography variant="body1">
          {info?.product_id ?? ""}{" "}
          {info?.is_in_intro_offer_period === "true" ? "(INTRO)" : ""}{" "}
          {info?.is_trial_period === "true" ? "(TRIAL)" : ""}
        </Typography>
        <Typography variant="body2">
          {info?.purchase_date_ms
            ? `${new Date(Number(info?.purchase_date_ms)).toLocaleString()} `
            : ""}
          &gt; 
          {info?.expires_date_ms
            ? `${new Date(Number(info?.expires_date_ms)).toLocaleString()} `
            : ""}
        </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          <List>
            {info?.purchase_date_ms ? (
              <ListItem dense>
                <ListItemAvatar>
                  <Avatar>
                    <TodayIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Purchase Date"}
                  secondary={new Date(
                    Number(info?.purchase_date_ms)
                  ).toLocaleString()}
                />
              </ListItem>
            ) : null}
            {info?.expires_date_ms ? (
              <ListItem dense>
                <ListItemAvatar>
                  <Avatar>
                    <TodayIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Expire Date"}
                  secondary={new Date(
                    Number(info?.expires_date_ms)
                  ).toLocaleString()}
                />
              </ListItem>
            ) : null}
          </List>
          <Box
            p={2}
            style={{
              backgroundColor: "#000",
              overflow: "scroll",
              WebkitOverflowScrolling: "touch",
              width: "100%",
            }}
          >
            <pre>{JSON.stringify(info, null, 2)}</pre>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
