import React, { FC, ReactNode } from "react";
import {
  InAppSubscription,
  useVerifyReceiptAndroidQuery,
  useVerifyReceiptIosQuery,
  IosLatestReceiptInfo,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import TodayIcon from "@material-ui/icons/Today";
import EventIcon from "@material-ui/icons/Event";
import CancelIcon from "@material-ui/icons/Cancel";
import CallMissedIcon from "@material-ui/icons/CallMissed";
import ErrorIcon from "@material-ui/icons/Error";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PaymentIcon from "@material-ui/icons/Payment";
import Avatar from "@material-ui/core/Avatar";
import { DeviceIcon } from "../Devices/Icon";
import { useStyles } from "../Devices/ListItem";
import { LinearProgress, Link } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AccordionCode } from "../Code/Accordion";
import last from "lodash/last";

interface SubscriptionDetailsProps {
  subscription: InAppSubscription;
}

export const SubscriptionDetails = ({
  subscription,
}: SubscriptionDetailsProps) => {
  const classes = useStyles();
  const verifyReceiptAndroidQuery = useVerifyReceiptAndroidQuery({
    skip: !subscription?.productId || !subscription?.purchaseToken,
    variables: {
      sku: subscription?.productId ?? "",
      token: subscription?.purchaseToken ?? "",
    },
  });
  const verifyReceiptIosQuery = useVerifyReceiptIosQuery({
    skip: !!subscription?.signatureAndroid || !subscription?.transactionReceipt,
    variables: {
      receipt: subscription?.transactionReceipt ?? "",
    },
  });
  const verifiedIos = verifyReceiptIosQuery.data?.verifyReceiptIOS;
  const verifiedAndroid = verifyReceiptAndroidQuery.data?.verifyReceiptAndroid;
  return (
    <>
      {verifyReceiptAndroidQuery.loading || verifyReceiptIosQuery.loading ? (
        <LinearProgress />
      ) : null}
      <Box p={2}>
        <Box pb={2}>
          <Card>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Subscription Details
              </Typography>
              <List>
                <ListItem dense>
                  <ListItemAvatar>
                    <Avatar>
                      <DeviceIcon os={subscription?.os} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={subscription?.productId}
                    secondary={
                      subscription?.originalTransactionIdentifierIOS ||
                      verifiedAndroid?.orderId ? (
                        <Link
                          href={getPlayConsoleOrderUrl(
                            verifiedAndroid?.orderId ?? ""
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {verifiedAndroid?.orderId}
                        </Link>
                      ) : null
                    }
                  />
                </ListItem>
                <ListItem dense>
                  <ListItemAvatar>
                    <Avatar>
                      <EventIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={"Date"}
                    secondary={new Date(
                      subscription?.transactionDate
                    ).toLocaleString()}
                  />
                </ListItem>
                {verifiedAndroid?.startTime ? (
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar>
                        <TodayIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={"Start Date"}
                      secondary={new Date(
                        verifiedAndroid?.startTime
                      ).toLocaleString()}
                    />
                  </ListItem>
                ) : null}
                {verifiedAndroid?.expiryTime ? (
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar>
                        <TodayIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={"Expiration Date"}
                      secondary={new Date(
                        verifiedAndroid?.expiryTime
                      ).toLocaleString()}
                    />
                  </ListItem>
                ) : null}
                {subscription?.originalTransactionDateIOS ? (
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar>
                        <TodayIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={"Original Date"}
                      secondary={new Date(
                        subscription?.originalTransactionDateIOS
                      ).toLocaleString()}
                    />
                  </ListItem>
                ) : null}
                {typeof verifiedAndroid?.autoRenewing === "boolean" ? (
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar
                        className={
                          verifiedAndroid?.autoRenewing
                            ? classes.primary
                            : classes.secondary
                        }
                      >
                        {verifiedAndroid?.autoRenewing ? (
                          <PlayCircleOutlineIcon />
                        ) : (
                          <PauseCircleOutlineIcon />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Auto Renew"
                      secondary={String(verifiedAndroid?.autoRenewing)}
                    />
                  </ListItem>
                ) : null}
                {typeof verifiedAndroid?.cancelReason === "number" ? (
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar
                        className={
                          verifiedAndroid?.cancelReason
                            ? classes.primary
                            : classes.secondary
                        }
                      >
                        {verifiedAndroid?.cancelReason ? (
                          <PlayCircleOutlineIcon />
                        ) : (
                          <PauseCircleOutlineIcon />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Cancel Reason"
                      secondary={String(verifiedAndroid?.cancelReason)}
                    />
                  </ListItem>
                ) : null}
                {typeof verifiedAndroid?.purchaseType === "number" ? (
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar
                        className={
                          verifiedAndroid?.purchaseType
                            ? classes.primary
                            : classes.secondary
                        }
                      >
                        {verifiedAndroid?.purchaseType ? (
                          <PlayCircleOutlineIcon />
                        ) : (
                          <PauseCircleOutlineIcon />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Purchase Type"
                      secondary={String(verifiedAndroid?.purchaseType)}
                    />
                  </ListItem>
                ) : null}
                {typeof verifiedAndroid?.acknowledgementState === "number" ? (
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar
                        className={
                          verifiedAndroid?.acknowledgementState
                            ? classes.primary
                            : classes.secondary
                        }
                      >
                        {verifiedAndroid?.acknowledgementState ? (
                          <PlayCircleOutlineIcon />
                        ) : (
                          <PauseCircleOutlineIcon />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Acknowledgement State"
                      secondary={String(verifiedAndroid?.acknowledgementState)}
                    />
                  </ListItem>
                ) : null}
                {last(verifiedIos?.latest_receipt_info)?.expires_date_ms ? (
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar className={
                          Number(last(verifiedIos?.latest_receipt_info)?.expires_date_ms) > Date.now()
                            ? classes.primary
                            : classes.secondary
                        }>
                        <TodayIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={"Expires Date"}
                      secondary={new Date(
                        Number(last(verifiedIos?.latest_receipt_info)?.expires_date_ms)
                      ).toLocaleString()}
                    />
                  </ListItem>
                ) : null}
                {verifiedIos?.pending_renewal_info?.[0]?.auto_renew_status ? (
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar
                        className={
                          verifiedIos?.pending_renewal_info?.[0]?.auto_renew_status === '1'
                            ? classes.primary
                            : classes.secondary
                        }
                      >
                        {verifiedIos?.pending_renewal_info?.[0]?.auto_renew_status === '1' ? (
                          <PlayCircleOutlineIcon />
                        ) : (
                          <PauseCircleOutlineIcon />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Auto Renew Status"
                      secondary={String(verifiedIos?.pending_renewal_info?.[0]?.auto_renew_status === '1')}
                    />
                  </ListItem>
                ) : null}
                {verifiedIos?.pending_renewal_info?.[0]?.expiration_intent ? (
                  <ListItem dense>
                    <ListItemAvatar>
                      <Avatar
                        className={
                          verifiedIos?.pending_renewal_info?.[0]?.expiration_intent === '0'
                            ? classes.primary
                            : classes.secondary
                        }
                      >
                        <ExpirationIntentIcon intent={verifiedIos?.pending_renewal_info?.[0]?.expiration_intent} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Expiration Intent"
                      secondary={expirationIntentDescription(verifiedIos?.pending_renewal_info?.[0]?.expiration_intent)}
                    />
                  </ListItem>
                ) : null}
              </List>
            </CardContent>
          </Card>
        </Box>
        {subscription ? (
          <Box pb={2}>
            <AccordionCode id="subscription" title="Subscription">
              <pre>{JSON.stringify(subscription, null, 2)}</pre>
            </AccordionCode>
          </Box>
        ) : null}
        {verifyReceiptAndroidQuery.data ? (
          <Box pb={2}>
            <AccordionCode
              id="verifyReceiptDataAndroid"
              title="Verify Receipt Android"
            >
              <pre>
                {JSON.stringify(verifyReceiptAndroidQuery.data, null, 2)}
              </pre>
            </AccordionCode>
          </Box>
        ) : null}
        {verifyReceiptAndroidQuery.error ? (
          <Box pb={2}>
            <AccordionCode
              id="verifyReceiptErrorAndroid"
              title="Verify Receipt Android Error"
            >
              <pre>
                {JSON.stringify(verifyReceiptAndroidQuery.error, null, 2)}
              </pre>
            </AccordionCode>
          </Box>
        ) : null}
        {verifyReceiptIosQuery.data ? (
          <Box pb={2}>
            <AccordionCode id="verifyReceiptDataIOS" title="Verify Receipt IOS">
              <pre>{JSON.stringify(verifyReceiptIosQuery.data, null, 2)}</pre>
            </AccordionCode>
          </Box>
        ) : null}
        {verifyReceiptIosQuery.error ? (
          <Box pb={2}>
            <AccordionCode
              id="verifyReceiptErrorIOS"
              title="Verify Receipt IOS Error"
            >
              <pre>{JSON.stringify(verifyReceiptIosQuery.error, null, 2)}</pre>
            </AccordionCode>
          </Box>
        ) : null}
        <Box pb={2}>
          {verifiedIos?.latest_receipt_info?.map((info, i) => (
            <LatestRenewalInfoAccordion
              info={info}
              id={info?.transaction_id ?? ""}
              key={i}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

const LatestRenewalInfoAccordion = ({
  info,
  id,
}: {
  id: string | number;
  info?: IosLatestReceiptInfo | null;
}) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <div>
        <Typography variant="body1">
          {info?.product_id ?? ""}{" "}
          {info?.is_in_intro_offer_period === "true" ? "(INTRO)" : ""}{" "}
          {info?.is_trial_period === "true" ? "(TRIAL)" : ""}
        </Typography>
        <Typography variant="body2">
          {info?.purchase_date_ms
            ? `${new Date(Number(info?.purchase_date_ms)).toLocaleString()} `
            : ""}
          &gt; 
          {info?.expires_date_ms
            ? `${new Date(Number(info?.expires_date_ms)).toLocaleString()} `
            : ""}
        </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          <List>
            {info?.purchase_date_ms ? (
              <ListItem dense>
                <ListItemAvatar>
                  <Avatar>
                    <TodayIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Purchase Date"}
                  secondary={new Date(
                    Number(info?.purchase_date_ms)
                  ).toLocaleString()}
                />
              </ListItem>
            ) : null}
            {info?.expires_date_ms ? (
              <ListItem dense>
                <ListItemAvatar>
                  <Avatar>
                    <TodayIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={"Expire Date"}
                  secondary={new Date(
                    Number(info?.expires_date_ms)
                  ).toLocaleString()}
                />
              </ListItem>
            ) : null}
          </List>
          <Box
            p={2}
            style={{
              backgroundColor: "#000",
              overflow: "scroll",
              WebkitOverflowScrolling: "touch",
              width: "100%",
            }}
          >
            <pre>{JSON.stringify(info, null, 2)}</pre>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

function getPlayConsoleOrderUrl(orderId: string) {
  return `https://play.google.com/apps/publish/?pli=1&account=7449338475185612002#OrderManagementPlace:orderid=${orderId}`;
}

function expirationIntentDescription (intent: string) {
  switch (intent) {
    case '1': return 'The customer voluntarily canceled their subscription.'
    case '2': return 'Billing error; for example, the customer\'s payment information was no longer valid.'
    case '3': return 'The customer did not agree to a recent price increase.'
    case '4': return 'The product was not available for purchase at the time of renewal.'
    case '5': return 'Unknown error.'
    default: return ''
  }
}

function ExpirationIntentIcon ({ intent }: { intent?: string|null }) {
  switch (intent) {
    case '1': return <CancelIcon />
    case '2': return <PaymentIcon />
    case '3': return <LocalOfferIcon />
    case '4': return <CallMissedIcon />
    case '5': return <ErrorIcon />
    default: return null
  } 
}