import { useRef, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles';

export const useFlasher = () => {
  const theme = useTheme()
  const ref = useRef<any>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.setAttribute(
        "style",
        `box-shadow: 0 0 12px 1px ${theme.palette.primary.main};
        //  background-color: ${theme.palette.primary.main};
         transition: box-shadow 50ms ease-out;`
      );
      const timer = setTimeout(() => {
        if (ref.current) {
          ref.current.setAttribute("style", "")
        }
      }, 200);
      return () => clearTimeout(timer)
    }
  });
  return ref;
};