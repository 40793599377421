import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import TypographyLink from "@material-ui/core/Link";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuIcon from "@material-ui/icons/Menu";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import { LayoutControls } from "./controls"

export const Navbar: FC<{
  menu?: any;
}> = ({ menu, children }) => {
  const { loading } = LayoutControls.useControls()
  return (
    <AppBar style={{ boxShadow: 'none' }}>
      <Toolbar>
        {menu}
        <Box display="flex" alignItems="center">
          <IconButton to="/" style={{ textDecoration: "none", marginRight: 4 }} component={Link}>
            <img 
              src={`${process.env.PUBLIC_URL}/images/icon/icon-192x192.png`} 
              alt="Purchases Logo"
              height={40} 
              width={40} 
            />
          </IconButton>
          <TypographyLink
            to="/"
            style={{ textDecoration: "none" }}
            component={Link}
          >
            <Typography variant="h4" color="textPrimary">
              Purchases
            </Typography>
          </TypographyLink>
        </Box>
        <Box flex={1} />
        {children}
      </Toolbar>
      {loading ? <LinearProgress /> : null}
    </AppBar>
  );
};

export default Navbar