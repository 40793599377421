import React from "react";
import { CardUserDevice } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import VisibilityOn from "@material-ui/icons/Visibility";
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DevicesIcon from '@material-ui/icons/Devices';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EmailIcon from "@material-ui/icons/Email";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import { DeviceIcon } from "./Icon";
import { useStyles, getActiveTooltip, getHiddenTooltip } from "./ListItem";
import { AccordionCode } from "../Code/Accordion";

interface DeviceDetailsProps {
  device: CardUserDevice;
}

export const DeviceDetails = ({ device }: DeviceDetailsProps) => {
  const classes = useStyles()
  return (
    <Box p={2}>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Device Details
          </Typography>
          <List>
            <ListItem dense>
              <ListItemAvatar>
                <Avatar className={device.active ? classes.primary : classes.secondary}>
                  <DeviceIcon os={device?.os} />
                </Avatar>  
              </ListItemAvatar>
              <ListItemText 
                primary="OS"
                secondary={device.os}
              />
            </ListItem>
            <ListItem dense>
              <ListItemAvatar>
                <Avatar>
                  <DevicesIcon />
                </Avatar>  
              </ListItemAvatar>
              <ListItemText 
                primary="Device Type"
                secondary={device.deviceType}
              />
            </ListItem>
            <ListItem dense>
              <ListItemAvatar>
                <Avatar>
                  <EmailIcon />
                </Avatar>  
              </ListItemAvatar>
              <ListItemText 
                primary="Email"
                secondary={device.email}
              />
            </ListItem>
            <ListItem dense>
              <ListItemAvatar>
                <Avatar className={device.active ? classes.primary : classes.secondary}>
                  {device?.active ? <PlayCircleOutlineIcon /> : <PauseCircleOutlineIcon />}
                </Avatar>  
              </ListItemAvatar>
              <ListItemText 
                primary={device.active ? "Active" : "Inactive"}
                secondary={getActiveTooltip(device.active)}
              />
            </ListItem>
            <ListItem dense>
              <ListItemAvatar>
                <Avatar className={device.hidden ? classes.primary : classes.secondary}>
                  {device?.hidden ? <VisibilityOff /> : <VisibilityOn />}
                </Avatar>  
              </ListItemAvatar>
              <ListItemText 
                primary={device?.hidden ? "Hidden" : "Visible"}
                secondary={getHiddenTooltip(device?.hidden)}
              />
            </ListItem>
            <ListItem dense>
              <ListItemAvatar>
                <Avatar>
                  <CalendarTodayIcon />
                </Avatar>  
              </ListItemAvatar>
              <ListItemText 
                primary={"Date"}
                secondary={new Date(device?.date).toLocaleString()}
              />
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Box py={2}>
        <AccordionCode title="Full Details" id="details">
          <pre>{JSON.stringify(device, null, 2)}</pre>
        </AccordionCode>
      </Box>
    </Box>
  );
};
