import React, { useState } from "react";
import { contextControllerHOC } from "../../utils/controls";
import Checkbox from "@material-ui/core/Checkbox";


export const SelectManyControls = contextControllerHOC(useBuildSelectManyControls)

export function useBuildSelectManyControls<V>() {
  const [selected, setSelected] = useState<V[]>([]);

  const handleSelectOne = (event: any, value: V) => {
    const selectedIndex = selected.indexOf(value);
    let newSelected: V[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, value);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return {
    selected,
    setSelected,
    handleSelectOne,
  };
}

export function ToggleSelected<V> ({ all }: { all?: V[] }) {
  const { selected, setSelected } = SelectManyControls.useControls()
  const allSelected = selected.length === all?.length;
  const indeterminate = selected.length > 0 && selected.length < (all?.length ?? 0);
  const handleSelectAll = (event: any, all?: V[]) => {
    if (all && event.target.checked) {
      setSelected(all);
    } else {
      setSelected([]);
    }
  };
  return  <Checkbox
    checked={allSelected}
    color="primary"
    indeterminate={indeterminate}
    onChange={(event) => handleSelectAll(event, all)}
  />
}

export const CheckboxCell = ({ id }: { id: string }) => {
  const { selected, handleSelectOne } = SelectManyControls.useControls()
  return (
    <Checkbox
      checked={selected.indexOf(id) !== -1}
      color="primary"
      onClick={event => event.stopPropagation()}
      onChange={(event) => {
        event.stopPropagation()
        handleSelectOne(event, id || "")
      }
      }
      value="true"
    />
  );
};