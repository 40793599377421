import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import {
  CardUserFilter,
  useCardUserListQuery,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getInitials } from "../../utils/string";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState<CardUserFilter>({});
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [perPage, setRowsPerPage] = useState(5);
  const { data, loading } = useCardUserListQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filter,
      pagination: {
        page: page + 1,
        perPage,
      },
    },
  });
  const users = (data && data.cardUserList && data.cardUserList.data) || [];
  const total = (data && data.cardUserList && data.cardUserList.total) || 0;

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      setSelectedUsers(
        (users || []).map((user: any) => user && user.userId).filter((v) => v)
      );
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectOne = (event: any, uid: string) => {
    const selectedIndex = selectedUsers.indexOf(uid);
    let newSelectedUsers: string[] = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, uid);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event: any, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Box p={3}>
        <Card>
          <CardHeader
            title="Users"
            action={
              <FormControl>
                <InputLabel>Active Subscription</InputLabel>
              <Select
                multiple
                style={{ minWidth: 200 }}
                value={filter?.activeSubscription?.IN ?? []}
                onChange={(ev: any) => {
                  const { activeSubscription, ...rest } = filter
                  const IN = ev.target.value
                  if (IN.length) {
                    setFilter({
                      ...rest,
                      activeSubscription: { IN },
                    })
                  } else {
                    setFilter(rest)
                  }
                }}
              >
                <MenuItem value="CARDS_FREE">Free</MenuItem>
                <MenuItem value="CARDS_9_99">iOS</MenuItem>
                <MenuItem value="cards_9_99">Android</MenuItem>
              </Select>
              </FormControl>
            }
          />
          <Divider />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedUsers.length === users.length}
                      color="primary"
                      indeterminate={
                        selectedUsers.length > 0 &&
                        selectedUsers.length < users.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>UID</TableCell>
                  <TableCell>Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(users || []).map(
                  (user) =>
                    user &&
                    user.userId && (
                      <TableRow
                        hover
                        key={user.userId}
                        selected={selectedUsers.indexOf(user.userId) !== -1}
                        onClick={() => history.push(`/user/${user.userId}`)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedUsers.indexOf(user.userId) !== -1}
                            color="primary"
                            onChange={(event) =>
                              handleSelectOne(event, user.userId || "")
                            }
                            value="true"
                          />
                        </TableCell>
                        <TableCell>
                          <div className={classes.nameContainer}>
                            <Avatar
                              className={classes.avatar}
                              src={user.photoURL || undefined}
                            >
                              {getInitials(user.displayName || user.email)}
                            </Avatar>
                            <Typography variant="body1">
                              {user.displayName}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>{user.email} {user.activeSubscription ? `(${user.activeSubscription})`: null}</TableCell>
                        <TableCell>{user.userId}</TableCell>
                        <TableCell>
                          {moment(user.date).format("MM/DD/YYYY")}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </CardContent>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <TablePagination
              component="div"
              count={total || 0}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </CardActions>
        </Card>
      </Box>
    </>
  );
};
