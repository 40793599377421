import React from "react";
import VerificationsTable from "./Table";
import { VerificationsControlsProvider } from "./controls";

export default () => {
  return (
    <VerificationsControlsProvider>
      <VerificationsTable />
    </VerificationsControlsProvider>
  );
};
