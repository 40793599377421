import Box from "@material-ui/core/Box";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {
  CardUserDevice,
  useSetDeviceHiddenMutation,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import VisibilityOn from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { DeviceIcon } from "./Icon";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primary: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    secondary: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
  }),
);

export const getActiveTooltip = (active?: boolean|null) => active ? "Device is the most recently used by this account" : "Device is not the most recently used by this account"
export const getHiddenTooltip = (hidden?: boolean|null) => hidden ? "Hidden to allow user to login with a different account on this device" : "Hide to allow user to login with a different account on this device"

interface DeviceListItemProps {
  active?: boolean;
  device: CardUserDevice;
  onClick?: () => any;
}

export const DeviceListItem = ({
  active,
  device,
  onClick,
}: DeviceListItemProps) => {
  const classes = useStyles()
  const [setHidden, { loading }] = useSetDeviceHiddenMutation();
  return (
    <ListItem onClick={onClick} selected={active} button>
      <Tooltip title={
        <Typography>
          {getActiveTooltip(device?.active)}
        </Typography>
      }>
      <ListItemAvatar>
        <Avatar className={device.active ? classes.primary : classes.secondary}>
          <DeviceIcon os={device?.os} />
        </Avatar>
      </ListItemAvatar>
      </Tooltip>
      <ListItemText
        primary={device.deviceId ?? ""}
        primaryTypographyProps={{ noWrap: true }}
        secondary={new Date(device.date).toLocaleString() ?? ""}
        secondaryTypographyProps={{ noWrap: true }}
      ></ListItemText>
      <ListItemSecondaryAction>
        <Tooltip
          title={
            <Typography>
              {getHiddenTooltip(device?.hidden)}
            </Typography>
          }
        >
          <div>
            <IconButton
              size="small"
              onClick={() =>
                setHidden({
                  variables: { id: device?._id, hidden: !device?.hidden },
                })
              }
              style={{ position: "relative" }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : null}
              {device?.hidden ? <VisibilityOff /> : <VisibilityOn />}
            </IconButton>
          </div>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default DeviceListItem;
