import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {
  BackgroundLocationFilter,
  useCardUserTransactionsQuery,
  useCardUserAtriumTransactionsQuery,
  PlaidTransaction,
  AtriumTransaction
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { FC, useRef, useState, useMemo } from "react";
import DateRangePicker from "../../components/Date/Range";
import { useIsMdUp } from "../../components/Layout/utils";
import {
  circlesOptions,
  heatmapOptions,
  Layer,
  Map,
  Source,
} from "../../components/Map";
import { locationPopup } from "../../utils/location";
import TransactionCards from "../Map/TransactionCards";
import TransactionMarkers, { AtriumTransactionMarkers } from "../Map/TransactionMarkers";
import TransactionsCenter from "../Map/TransactionsCenter";
import {
  useUserGeoJson,
  useActiveTransactionHawk,
  useSetActiveTransactionHawk,
  useSetVerifyTransactionHawk,
  useSetVerifyAtriumTransactionHawk,
  useUserDateRangeHawk,
  useSetUserDateRangeHawk,
  useUserControlsContext,
} from "./controls";
import { hasLocationLatLon, hasLatitudeLongitude } from "../../utils/location";

export const CardUserLocations = () => {
  const isMdUp = useIsMdUp();
  const map = useRef<any>(null);
  const timer = useRef<any>(null);
  const { userId } = useUserControlsContext()
  const activeTransaction = useActiveTransactionHawk();
  const setActiveTransaction = useSetActiveTransactionHawk();
  const setVerifyTransaction = useSetVerifyTransactionHawk();
  const setVerifyAtriumTransaction = useSetVerifyAtriumTransactionHawk();
  const dateRange = useUserDateRangeHawk();
  const dateRangeChange = useSetUserDateRangeHawk();
  const variables = useMemo(() => {
    const variables: any = {
      userId,
    };
    if (dateRange.start)
      variables.startDate = dateRange.start.format('YYYY-MM-DD');
    if (dateRange.end) variables.endDate = dateRange.end.clone().add(1, 'day').format('YYYY-MM-DD');
    return variables;
  }, [userId, dateRange]);
  const atrium = useCardUserAtriumTransactionsQuery({
    variables
  })
  const { data } = useCardUserTransactionsQuery({
    variables,
  });
  const locationTransactions = (data?.cardUserById?.getCachedTransactions?.transactions?.filter?.(
    hasLocationLatLon
  ) ?? []) as PlaidTransaction[];
  const locationAtriumTransactions = (atrium.data?.cardUserById?.atriumUser?.cachedTransactions?.data?.filter?.(
    hasLatitudeLongitude
  ) ?? []) as AtriumTransaction[];
  const geoJSON = useUserGeoJson(userId, dateRange);
  const [filter, setFilter] = useState<BackgroundLocationFilter | null>(null);
  const [shouldFitBounds, setShouldFitBounds] = useState(true);
  const handleLoad = (ev: any) => {
    map.current = ev.target;
  };
  const handleDrag = (ev: any) => {
    const bounds = ev.target.getBounds();
    const nextFilter: BackgroundLocationFilter = {
      latitude: {
        GT: bounds.getSouth(),
        LT: bounds.getNorth(),
      },
      longitude: {
        GT: bounds.getWest(),
        LT: bounds.getEast(),
      },
    };
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setFilter(nextFilter);
      setShouldFitBounds(false);
    }, 200);
  };
  const total = geoJSON?.data?.features?.length || 0;
  return (
    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title="User Location"
        action={
          <Box p={1} display="flex" alignItems="center">
            {geoJSON.loading ? (
              <CircularProgress
                style={{ width: 24, height: 24, marginRight: 4 }}
              />
            ) : null}
            <DateRangePicker
              disableToolbar
              variant="inline"
              value={dateRange.start}
              end={dateRange.end}
              onChange={dateRangeChange}
            />
          </Box>
        }
      />
      <CardContent style={{ paddingTop: 0, flex: 1 }}>
        <CardMedia
          style={{
            position: "relative",
            overflow: "hidden",
            width: "100%",
            ...(isMdUp ? { height: "100%" } : { height: 400 }),
          }}
        >
          <Map
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
            options={{ center: [0, 0] }}
            onDrag={handleDrag}
            onLoad={handleLoad}
          >
            <Source id="location" data={geoJSON.data} fitBounds={true}>
              <Layer
                id="location-heat"
                source="location"
                options={heatmapOptions()}
              />
              <Layer
                id="location-circles"
                source="location"
                options={circlesOptions(geoJSON.data)}
                renderPopup={locationPopup}
              />
            </Source>
            <TransactionMarkers
              transactions={locationTransactions}
              onClick={setVerifyTransaction}
            />
            <AtriumTransactionMarkers
              transactions={locationAtriumTransactions}
              onClick={setVerifyAtriumTransaction}
            />
            <TransactionsCenter
              shouldFitBounds={shouldFitBounds}
              transactions={locationTransactions}
              activeTransaction={activeTransaction}
            />
          </Map>
          <TransactionCards
            transactions={locationTransactions}
            activeTransaction={activeTransaction}
            setActiveTransaction={(v) => {
              setShouldFitBounds(false);
              setActiveTransaction(v);
            }}
          />
        </CardMedia>
      </CardContent>
      <Box px={3} display="flex" alignItems="center" justifyContent="flex-end">
        {geoJSON.loading ? (
          <CircularProgress style={{ width: 10, height: 10, marginRight: 4 }} />
        ) : geoJSON.error ? (
          <Typography color="error">{geoJSON.error}</Typography>
        ) : (
          <Typography>{total} Data Points</Typography>
        )}
      </Box>
      <CardActions></CardActions>
    </Card>
  );
};
