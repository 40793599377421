import React, { FC } from "react";
import Select, { SelectProps } from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

export const LoadingSelect: FC<SelectProps & { loading?: boolean }> = ({
  loading,
  disabled,
  children,
  ...props
}) => {
  return (
    <Select
      IconComponent={loading ? () => <CircularProgress size={16} /> : ArrowDropDown} 
      disabled={loading || disabled}
      {...props}
    >
      {children}
    </Select>
  );
};
