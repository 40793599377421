import React from "react";
import { AtriumTransaction } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import { TableList } from "../Table/List";
import TransactionListItem from "./ListItem";
import TransactionsNotFound from "../Transactions/NotFound";

interface TransactionListProps {
  loading?: boolean;
  activeTransaction?: AtriumTransaction | null;
  setActiveTransaction?: (v: AtriumTransaction | null) => any;
  transactions?: (AtriumTransaction | null)[];
}

export const AtriumTransactionList = ({
  loading,
  transactions = [],
  activeTransaction,
  setActiveTransaction,
}: TransactionListProps) => {
  return (
    <TableList
      loading={loading}
      items={transactions}
      getItemId={(transaction, i) => transaction.guid || i}
      renderItem={(transaction, i) => (
        <TransactionListItem
          transaction={transaction}
          active={transactions === activeTransaction}
          onClick={
            setActiveTransaction
              ? () =>
                  setActiveTransaction(
                    transaction === activeTransaction ? null : transaction
                  )
              : undefined
          }
        />
      )}
    >
      <TransactionsNotFound />
    </TableList>
  );
};
