import React, { createContext, useState, FC, useContext, useEffect } from 'react'
import { auth, User } from 'firebase/app'
import 'firebase/auth'

interface IAuth {
  initialized: boolean;
  user: User|null
}

const initialAuth = {
  initialized: false,
  user: null
}

export const AuthContext = createContext<IAuth>(initialAuth)

export const AuthProvider: FC = ({ children }) => {
  const [state, setState] = useState<IAuth>(initialAuth)

  useEffect(() => auth().onAuthStateChanged(user => {
    setState({ initialized: true, user })
  }), [])

  return (
    <AuthContext.Provider value={state}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
export const useUser = () => useContext(AuthContext).user

export const appOptions = {
  apiKey: "AIzaSyCCtXYmkLd3gh6fKjSCGOWhsYoeKccYq-g",
  authDomain: "xyo-network-1522800011804.firebaseapp.com",
  databaseURL: "https://xyo-network-1522800011804.firebaseio.com",
  projectId: "xyo-network-1522800011804",
  storageBucket: "xyo-network-1522800011804.appspot.com",
  messagingSenderId: "542704523793",
}