import React, { FC, useMemo } from "react";
import { formatNumber } from "../../utils/number";
import Typography from '@material-ui/core/Typography'

export const Balance: FC<any> = ({
  children,
  prefix = "$",
  component: Cmp,
  ...props
}) => {
  const value = useMemo(() => {
    if (!children) return null;
    const number = Number(children);
    if (isNaN(number)) return null;
    return formatNumber(number);
  }, [children]);
  if (!value) return null;
  if (Cmp)
    return (
      <Cmp {...props}>
        {prefix}
        {value}
      </Cmp>
    );
  return (
    <>
      {prefix}
      {value}
    </>
  );
};

export const BoldBalance = ({ value }: { value?: number|null }) => {
  return <Balance
    variant="body2"
    component={Typography}
    color={(value || 0) > 0 ? "textSecondary" : "inherit"}
    prefix={(value || 0) > 0 ? "- $" : "$"}
    style={{ fontWeight: "bold" }}
    noWrap
  >
    {Math.abs(value || 0)}
  </Balance>;
};

export default Balance;
