import Box from "@material-ui/core/Box";
import { PlaidTransaction } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { useState, useRef } from "react";
import { MapCard } from "./Card";

export default ({
  transactions,
  activeTransaction,
  setActiveTransaction,
}: {
  transactions: PlaidTransaction[];
  activeTransaction: PlaidTransaction|null;
  setActiveTransaction: (t: PlaidTransaction) => any
}) => {
  const scrollElement = useRef<any>(null);
  return (
    <div
      ref={scrollElement as any}
      style={{
        position: "absolute",
        bottom: 0,
        paddingBottom: 24,
        paddingLeft: `calc(50% - 120px)`,
        maxWidth: "100%",
        overflowX: "auto",
        overflowY: "hidden",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        scrollSnapType: "x mandatory",
        WebkitOverflowScrolling: "touch",
        zIndex: 3
      }}
    >
      {transactions.map((transaction: PlaidTransaction, i: number) => (
        <Box
          minWidth={240}
          mr={2}
          style={{ scrollSnapAlign: "center" }}
          key={transaction.transaction_id || i}
        >
          <MapCard
            transaction={transaction}
            onClick={() => {
              if (scrollElement.current) {
                const left = i * (240 + 16);
                scrollElement.current.scroll({ left, behavior: "smooth" });
                setActiveTransaction(transaction)
              }
            }}
          />
        </Box>
      ))}
      <Box minWidth="calc(100% - 240px)" />
    </div>
  );
};
