import React, { FC } from 'react'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import WarningSharpIcon from '@material-ui/icons/WarningSharp'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import PaymentIcon from '@material-ui/icons/Payment';
import RestoreIcon from '@material-ui/icons/Restore';
import PauseIcon from '@material-ui/icons/Pause';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import capitalize from 'lodash/capitalize'
import toLower from 'lodash/toLower'
import flow from 'lodash/flow'

export const readableEventName = flow(getEventNameFromNotificationType, toLower, capitalize)

export const NotificationTypeIcon: FC<{ notificationType: number|undefined|null }> = ({ notificationType, ...props }) => {
  switch (notificationType) {
    case 1: return <RestoreIcon {...props} />
    case 2: return <RestoreIcon {...props} />
    case 3: return <CancelOutlinedIcon {...props} />
    case 4: return <PaymentIcon {...props} />
    case 5: return <WarningSharpIcon {...props} />
    case 6: return <RestoreIcon {...props} />
    case 7: return <RestoreIcon {...props} />
    case 8: return <ChangeHistoryIcon {...props} />
    case 9: return <WarningSharpIcon {...props} />
    case 10: return <PauseIcon {...props} />
    case 11: return <PauseIcon {...props} />
    case 12: return <RotateLeftIcon {...props} />
    case 13: return <RotateLeftIcon {...props} />
    default: return null
  }
}

export default NotificationTypeIcon

export function getEventNameFromNotificationType(notificationType: number|undefined|null) {
  switch (notificationType) {
    case 1:
      return 'subscription_recovered' // - A subscription was recovered from account hold.
    case 2:
      return 'subscription_renewed' // - An active subscription was renewed.
    case 3:
      return 'subscription_canceled' // - A subscription was either voluntarily or involuntarily cancelled. For voluntary cancellation, sent when the user cancels.
    case 4:
      return 'subscription_purchased' // - A new subscription was purchased.
    case 5:
      return 'subscription_on_hold' // - A subscription has entered account hold (if enabled).
    case 6:
      return 'subscription_in_grace_period' // - A subscription has entered grace period (if enabled).
    case 7:
      return 'subscription_restarted' // - User has reactivated their subscription from Play > Account > Subscriptions (requires opt-in for subscription restoration)
    case 8:
      return 'subscription_price_change_confirmed' // - A subscription price change has successfully been confirmed by the user.
    case 9:
      return 'subscription_deferred' // - A subscription's recurrence time has been extended.
    case 10:
      return 'subscription_paused' // - A subscription has been paused.
    case 11:
      return 'subscription_pause_schedule_changed' // - A subscription pause schedule has been changed.
    case 12:
      return 'subscription_revoked' // - A subscription has been revoked from the user before the expiration time.
    case 13:
      return 'subscription_expired' // - A subscription has expired.
    default:
      return ''
  }
}

