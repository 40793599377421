import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React, { FC, useState, useRef } from "react";
import { useIsMdUp } from "../../components/Layout/utils";
import { locationPopup } from "../../utils/location";
import {
  Map,
  Source,
  Layer,
  heatmapOptions,
  circlesOptions,
} from "../../components/Map";
import TransactionCards from "../Map/TransactionCards";
import TransactionMarkers from "../Map/TransactionMarkers";
import LocationMarkers from "../Map/LocationMarkers";
import TransactionsCenter from "../Map/TransactionsCenter";
import { TransactionControls } from "./controls";
import {
  useCardUserLocationsQuery,
  BackgroundLocationFilter,
  BackgroundLocation,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export const TransactionLocations: FC = ({}) => {
  const isMdUp = useIsMdUp();
  const map = useRef<any>(null);
  const timer = useRef<any>(null);
  const {
    loading,
    transaction,
    institution,
    account,
    near,
    during,
    geoJSON,
    dayDelta,
    setDayDelta,
  } = TransactionControls.useControls();
  const [filter, setFilter] = useState<BackgroundLocationFilter | null>(null);
  const [shouldFitBounds, setShouldFitBounds] = useState(true);
  const handleLoad = (ev: any) => {
    map.current = ev.target;
  };
  const handleDrag = (ev: any) => {
    const bounds = ev.target.getBounds();
    const nextFilter: BackgroundLocationFilter = {
      latitude: {
        GT: bounds.getSouth(),
        LT: bounds.getNorth(),
      },
      longitude: {
        GT: bounds.getWest(),
        LT: bounds.getEast(),
      },
    };
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setFilter(nextFilter);
      setShouldFitBounds(false);
    }, 200);
  };
  const total = geoJSON?.data?.features?.length || 0;
  return (
    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardHeader
        title="User Location"
        action={
          <Box p={1}>
            {loading ? (
              <CircularProgress style={{ width: 24, height: 24 }} />
            ) : (
              <>
              <InputLabel id="day-delta"></InputLabel>
              <Select
                id="day-delta"
                value={dayDelta}
                onChange={(ev: any) => setDayDelta(Number(ev.target.value))}
              >
                <MenuItem value={1}>1 day</MenuItem>
                <MenuItem value={2}>2 days</MenuItem>
                <MenuItem value={3}>3 days</MenuItem>
                <MenuItem value={4}>4 days</MenuItem>
              </Select>
              </>
            )}
          </Box>
        }
      />
      <CardContent style={{ paddingTop: 0, flex: 1 }}>
        <CardMedia
          style={{
            position: "relative",
            overflow: "hidden",
            width: "100%",
            ...(isMdUp ? { height: "100%" } : { height: 400 }),
          }}
        >
          <Map
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
            options={{ center: [0, 0] }}
            onDrag={handleDrag}
            onLoad={handleLoad}
          >
            {/* <LocationMarkers locations={near && near.data || []} /> */}
            <Source id="location" data={geoJSON.data} fitBounds={true}>
              <Layer
                id="location-heat"
                source="location"
                options={heatmapOptions()}
              />
              <Layer
                id="location-circles"
                source="location"
                options={circlesOptions(geoJSON.data)}
                renderPopup={locationPopup}
              />
            </Source>
            <TransactionMarkers transactions={[transaction]} />
            {/* <TransactionsCenter
              locations={near && near.data || []}
              shouldFitBounds={shouldFitBounds}
              transactions={[transaction]}
              activeTransaction={transaction}
            /> */}
          </Map>
        </CardMedia>
      </CardContent>
      <Box px={3} display="flex" alignItems="center" justifyContent="flex-end">
        {geoJSON.loading ? (
          <CircularProgress style={{ width: 10, height: 10, marginRight: 4 }} />
        ) : geoJSON.error ? (
          <Typography color="error">{geoJSON.error}</Typography>
        ) : (
          <>
            <Typography style={{ marginRight: 4 }}>
              {near?.total ?? 0} Points Near
            </Typography>
            {"  "}
            <Typography style={{ marginRight: 4 }}>
              {during?.total ?? 0} Points During
            </Typography>{" "}
            <Typography>{total} Data Points</Typography>
          </>
        )}
      </Box>
      <CardActions />
    </Card>
  );
};
