import React, { FC, useMemo, ReactElement } from "react";
import {
  PlaidAccount,
  PlaidTransaction,
  PlaidInstitution,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import Avatar from "@material-ui/core/Avatar";
import { formatNumber } from "../../utils/number";
import creditCard from "../Institutions/credit_card.png";

interface AccountListProps {
  activeAccount?: PlaidAccount | null;
  setActiveAccount?: (v: PlaidAccount | null, ev: any) => any;
  accounts?: (PlaidAccount | null)[];
  institutionsById?: {
    [institution_id: string]: PlaidInstitution;
  };
  accountTransactions?: {
    [account_id: string]: (PlaidTransaction | null)[];
  };
  renderAccountDetails?: (account: PlaidAccount) => ReactElement | null;
}

export const AccountList: FC<AccountListProps> = ({
  accounts = [],
  accountTransactions = {},
  institutionsById = {},
  activeAccount,
  setActiveAccount,
  renderAccountDetails,
}) => {
  return (
    <>
      {accounts.map((account, i) => account && (
        <Slide direction="up" in={true} key={account.account_id || i}>
          <Box mb={i < accounts.length - 1 ? 2 : 0}>
            <AccountCard
              account={account}
              institution={institutionsById[account.institution_id || ""]}
              active={account === activeAccount}
              onClick={
                setActiveAccount
                  ? (ev: any) =>
                      setActiveAccount(
                        account === activeAccount ? null : account,
                        ev
                      )
                  : undefined
              }
            />
            {renderAccountDetails ? renderAccountDetails(account) : null}
          </Box>
        </Slide>
      ))}
    </>
  );
};

interface AccountCardProps {
  active?: boolean;
  institution: PlaidInstitution;
  account: PlaidAccount;
  onClick?: (ev: any) => any;
}

export const AccountCard = ({
  active,
  institution,
  account,
  onClick,
}: AccountCardProps) => {
  const card = (
    <Card style={{ width: "100%", backgroundColor: "#303030" }}>
      <CardContent>
        <Box display="flex" flexDirection="row" alignItems="center">
          {institution && institution.logo ? (
            <Avatar src={`data:image/png;base64,${institution.logo}`} />
          ) : (
            <Skeleton variant="circle" width={40} height={40} />
          )}
          <Box ml={1} flex={1} textAlign="center">
            <Typography color="textSecondary">{account.name || ""}</Typography>
            <Typography variant="body2" component="p">
              **** **** **** {account.mask}
            </Typography>
          </Box>
        </Box>
        <Box overflow="hidden">
          <Slide in={active} direction="up" unmountOnExit>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={1}
            >
              {account.balances && account.balances.current ? (
                <Box>
                  <Typography variant="caption">Current Balance</Typography>
                  <Balance component={Typography} variant="h5">
                    {account.balances.current}
                  </Balance>
                </Box>
              ) : null}
              {account.balances && account.balances.current ? (
                <Box>
                  <Typography variant="caption">Available Balance</Typography>
                  <Balance component={Typography} variant="h5">
                    {account.balances.available}
                  </Balance>
                </Box>
              ) : null}
            </Box>
          </Slide>
        </Box>
      </CardContent>
    </Card>
  );

  if (!onClick) return card;

  return (
    <ButtonBase
      onClick={onClick}
      style={{ width: "100%", textAlign: "inherit" }}
    >
      {card}
    </ButtonBase>
  );
};

export const Balance: FC<any> = ({ children, component: Cmp, ...props }) => {
  const value = useMemo(() => {
    if (!children) return null;
    const number = Number(children);
    if (isNaN(number)) return null;
    return formatNumber(number);
  }, [children]);
  if (!value) return null;
  if (Cmp) return <Cmp {...props}>${value}</Cmp>;
  return <>${value}</>;
};

export const AccountsNotFound = () => {
  return (
      <CardContent>
        <Box textAlign="center">
          <img
            src={creditCard}
            alt="Credit Card Image"
            style={{ width: 180 }}
          />
          <Typography variant="h5">No Acccounts Found</Typography>
        </Box>
      </CardContent>
  );
};