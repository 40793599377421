export const apiVersion = 'v2'
export const key = process.env.REACT_APP_PLAID_KEY || ''
export const env = (process.env.REACT_APP_PLAID_ENV || 'development') as any
export const clientName = process.env.REACT_APP_PLAID_NAME || ''
export const webhook = process.env.REACT_APP_PLAID_WEBHOOK || ''
export const product = (process.env.REACT_APP_PLAID_PRODUCTS || '').split(',')
export const countryCodes = (process.env.REACT_APP_PLAID_COUNTRIES || '').split(',')

export const linkHandlerOptions = {
  env,
  key,
  clientName,
  apiVersion,
  product,
  webhook,
  countryCodes
};