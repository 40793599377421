import {
  PlaidAccount,
  PlaidTransaction,
  CardUserDevice,
  InAppSubscription,
  IosInAppSubscriptionS2S,
  AndroidInAppSubscriptionS2S,
  AtriumWebhookExecution,
  AtriumTransaction, 
  InAppSubscriptionError
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import { getHost } from "@xyo-network/coin-plaid-client/build/lib/graphql.client";
import React, { useRef, useState, createContext, useContext, FC } from "react";
import { env } from "../../providers/Plaid/options";
import { hawk, useHawkState, useHawkSetState } from "react-hawk";
import { useAsyncEffect } from "../../utils/controls";
import {
  localStoreHawk,
  useLocalStoreBooleanHawkSetState,
} from "../../utils/localStore";
import moment, { Moment } from "moment";

export type IUserControls = ReturnType<typeof useUserControls>;

export const UserControlsContext = createContext<IUserControls>({} as any);
export const useUserControlsContext = () => useContext(UserControlsContext);

export const UserControlsProvider: FC<{ userId: string }> = ({
  userId,
  children,
}) => {
  const controls = useUserControls(userId);
  return (
    <UserControlsContext.Provider value={controls}>
      {children}
    </UserControlsContext.Provider>
  );
};

// HAWK KEYS
const USER_TRANSACTIONS_EXPANDED = "user-transactions-expanded";
const USER_ATRIUM_TRANSACTIONS_EXPANDED = "user-atrium-transactions-expanded";
const USER_SUBSCRIPTIONS_EXPANDED = "user-subscriptions-expanded";
const USER_SUBSCRIPTION_ERRORS_EXPANDED = "user-subscription-errors-expanded";
const USER_SUBSCRIPTIONS_S2S_IOS_EXPANDED = "user-subscriptions-s2s-ios-expanded";
const USER_SUBSCRIPTIONS_S2S_ANDROID_EXPANDED = "user-subscriptions-s2s-android-expanded";
const USER_DEVICES_EXPANDED = "user-devices-expanded";
const USER_WEBHOOKS_EXPANDED = "user-webhooks-expanded";
const VERIFY_ATRIUM_TRANSACTION = "verify-atrium-transaction";
const ACTIVE_ATRIUM_TRANSACTION = "active-atrium-transaction";
const VERIFY_TRANSACTION = "verify-transaction";
const ACTIVE_TRANSACTION = "active-transaction";
const ACTIVE_SUBSCRIPTION = "active-subscription";
const ACTIVE_SUBSCRIPTION_ERROR = "active-subscription-error";
const ACTIVE_SUBSCRIPTION_S2S_IOS = "active-subscription-s2s-ios";
const ACTIVE_SUBSCRIPTION_S2S_ANDROID = "active-subscription-s2s-android";
const ACTIVE_DEVICE = "active-device";
const ACTIVE_WEBHOOK = "active-webhook";
const USER_DATE_RANGE = "user-date-range";

// STATE HAWKS
export const userTransactionsExpanded = localStoreHawk({
  key: USER_TRANSACTIONS_EXPANDED,
  default: true,
});
export const userAtriumTransactionsExpanded = localStoreHawk({
  key: USER_ATRIUM_TRANSACTIONS_EXPANDED,
  default: true,
});
export const userSubscriptionsExpanded = localStoreHawk({
  key: USER_SUBSCRIPTIONS_EXPANDED,
  default: true,
});
export const userSubscriptionErrorsExpanded = localStoreHawk({
  key: USER_SUBSCRIPTION_ERRORS_EXPANDED,
  default: true,
});
export const userSubscriptionsS2SIosExpanded = localStoreHawk({
  key: USER_SUBSCRIPTIONS_S2S_IOS_EXPANDED,
  default: true,
});
export const userSubscriptionsS2SAndroidExpanded = localStoreHawk({
  key: USER_SUBSCRIPTIONS_S2S_ANDROID_EXPANDED,
  default: true,
});
export const userDevicesExpanded = localStoreHawk({
  key: USER_DEVICES_EXPANDED,
  default: true,
});
export const userWebhooksExpanded = localStoreHawk({
  key: USER_WEBHOOKS_EXPANDED,
  default: true,
});
export const verifyAtriumTransactionHawk = hawk<AtriumTransaction | null>({
  key: VERIFY_ATRIUM_TRANSACTION,
  default: null,
});
export const activeAtriumTransactionHawk = hawk<AtriumTransaction | null>({
  key: ACTIVE_ATRIUM_TRANSACTION,
  default: null,
});
export const verifyTransactionHawk = hawk<PlaidTransaction | null>({
  key: VERIFY_TRANSACTION,
  default: null,
});
export const activeTransactionHawk = hawk<PlaidTransaction | null>({
  key: ACTIVE_TRANSACTION,
  default: null,
});
export const activeSubscriptionHawk = hawk<InAppSubscription | null>({
  key: ACTIVE_SUBSCRIPTION,
  default: null,
});
export const activeSubscriptionErrorHawk = hawk<InAppSubscriptionError | null>({
  key: ACTIVE_SUBSCRIPTION_ERROR,
  default: null,
});
export const activeSubscriptionS2SIosHawk = hawk<IosInAppSubscriptionS2S | null>({
  key: ACTIVE_SUBSCRIPTION_S2S_IOS,
  default: null,
});
export const activeSubscriptionS2SAndroidHawk = hawk<AndroidInAppSubscriptionS2S | null>({
  key: ACTIVE_SUBSCRIPTION_S2S_ANDROID,
  default: null,
});
export const activeDeviceHawk = hawk<CardUserDevice | null>({
  key: ACTIVE_DEVICE,
  default: null,
});
export const activeWebhookHawk = hawk<AtriumWebhookExecution | null>({
  key: ACTIVE_WEBHOOK,
  default: null,
});
export const userDateRangeHawk = hawk({
  key: USER_DATE_RANGE,
  default: {
    start: moment().subtract(1, "week"),
    end: moment(),
  },
});

// USE STATE HAWKS
export const useUserTransactionsExpanded = () =>
  useHawkState(userTransactionsExpanded);
export const useUserAtriumTransactionsExpanded = () =>
  useHawkState(userAtriumTransactionsExpanded);
export const useUserSubscriptionsExpanded = () =>
  useHawkState(userSubscriptionsExpanded);
export const useUserSubscriptionErrorsExpanded = () =>
  useHawkState(userSubscriptionErrorsExpanded);
export const useUserSubscriptionsS2SIosExpanded = () =>
  useHawkState(userSubscriptionsS2SIosExpanded);
export const useUserSubscriptionsS2SAndroidExpanded = () =>
  useHawkState(userSubscriptionsS2SAndroidExpanded);
export const useUserDevicesExpanded = () => useHawkState(userDevicesExpanded);
export const useUserWebhooksExpanded = () => useHawkState(userWebhooksExpanded);
export const useVerifyAtriumTransactionHawk = () =>
  useHawkState(verifyAtriumTransactionHawk);
export const useActiveAtriumTransactionHawk = () =>
  useHawkState(activeAtriumTransactionHawk);
export const useVerifyTransactionHawk = () =>
  useHawkState(verifyTransactionHawk);
export const useActiveTransactionHawk = () =>
  useHawkState(activeTransactionHawk);
export const useActiveSubscriptionHawk = () =>
  useHawkState(activeSubscriptionHawk);
export const useActiveSubscriptionErrorHawk = () =>
  useHawkState(activeSubscriptionErrorHawk);
export const useActiveSubscriptionS2SIosHawk = () =>
  useHawkState(activeSubscriptionS2SIosHawk);
export const useActiveSubscriptionS2SAndroidHawk = () =>
  useHawkState(activeSubscriptionS2SAndroidHawk);
export const useActiveDeviceHawk = () => useHawkState(activeDeviceHawk);
export const useActiveWebhookHawk = () => useHawkState(activeWebhookHawk);
export const useUserDateRangeHawk = () => useHawkState(userDateRangeHawk);

// USE SET STATE HAWKS
export const useSetUserTransactionsExpanded = () =>
  useLocalStoreBooleanHawkSetState(userTransactionsExpanded);
export const useSetUserAtriumTransactionsExpanded = () =>
  useLocalStoreBooleanHawkSetState(userAtriumTransactionsExpanded);
export const useSetUserSubscriptionsExpanded = () =>
  useLocalStoreBooleanHawkSetState(userSubscriptionsExpanded);
export const useSetUserSubscriptionErrorsExpanded = () =>
  useLocalStoreBooleanHawkSetState(userSubscriptionErrorsExpanded);
export const useSetUserSubscriptionsS2SIosExpanded = () =>
  useLocalStoreBooleanHawkSetState(userSubscriptionsS2SIosExpanded);
export const useSetUserSubscriptionsS2SAndroidExpanded = () =>
  useLocalStoreBooleanHawkSetState(userSubscriptionsS2SAndroidExpanded);
export const useSetUserDevicesExpanded = () =>
  useLocalStoreBooleanHawkSetState(userDevicesExpanded);
export const useSetUserWebhooksExpanded = () =>
  useLocalStoreBooleanHawkSetState(userWebhooksExpanded);
export const useSetVerifyAtriumTransactionHawk = () =>
  useHawkSetState(verifyAtriumTransactionHawk);
export const useSetActiveAtriumTransactionHawk = () =>
  useHawkSetState(activeAtriumTransactionHawk);
export const useSetVerifyTransactionHawk = () =>
  useHawkSetState(verifyTransactionHawk);
export const useSetActiveTransactionHawk = () =>
  useHawkSetState(activeTransactionHawk);
export const useSetActiveSubscriptionHawk = () =>
  useHawkSetState(activeSubscriptionHawk);
export const useSetActiveSubscriptionErrorHawk = () =>
  useHawkSetState(activeSubscriptionErrorHawk);
export const useSetActiveSubscriptionS2SIosHawk = () =>
  useHawkSetState(activeSubscriptionS2SIosHawk);
export const useSetActiveSubscriptionS2SAndroidHawk = () =>
  useHawkSetState(activeSubscriptionS2SAndroidHawk);
export const useSetActiveDeviceHawk = () => useHawkSetState(activeDeviceHawk);
export const useSetActiveWebhookHawk = () => useHawkSetState(activeWebhookHawk);
export const useSetUserDateRangeHawk = () => {
  const dateRange = useHawkState(userDateRangeHawk);
  const setState = useHawkSetState(userDateRangeHawk);
  const dateRangeChange = (value: any) => {
    if (moment(value).isAfter(dateRange.end, 'day')) {
      setState((v) => ({
        ...v,
        end: value,
      }));
    } else if (moment(value).isBefore(dateRange.start, 'day')) {
      setState((v) => ({
        ...v,
        start: value,
      }));
    } else {
      setState((v) => ({
        start: moment(value).startOf("day"),
        end: moment(value).endOf("day"),
      }));
    }
  };
  return dateRangeChange;
};

type MomentRange = {
  start: Moment | null;
  end: Moment | null;
};

const useUserControls = (userId: string) => {
  return {
    userId,
  };
};

export function useUserGeoJson(userId: string, date?: MomentRange) {
  return useAsyncEffect(() => {
    return fetchUsersGeoJson(userId, date);
  }, [userId, date]);
}

const host = getHost(env);
export async function fetchUsersGeoJson(userId: string, range?: MomentRange) {
  let url = `${host}/geo/json/user/${userId}`;
  if (range && range.start && range.end)
    url += `/${range.start.format("YYYYMMDD")}/${range.end.format("YYYYMMDD")}`;
  else if (range && range.start) url += `/${range.start.format("YYYYMMDD")}`;
  const res = await fetch(url);
  return res.json();
}

export async function diffUserTransactions(userId?: string | null) {
  if (!userId) return null;
  const url = `${host}/diff?userId=${userId}`;
  const res = await fetch(url);
  return res.json();
}

export async function cacheUserTransactions(userId?: string | null) {
  if (!userId) return null;
  const url = `${host}/cache?userId=${userId}`;
  const res = await fetch(url);
  return res.json();
}
