import React from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ButtonBase from "@material-ui/core/ButtonBase";
import { PlaidTransaction } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { Balance } from "../../components/Accounts";

interface MapCardProps {
  disabled?: boolean
  transaction: PlaidTransaction;
  onClick: (ev: any) => any;
}

function toUtcDateString (d: string) {
  if (!d) return ''
  const date = new Date(d)
  return `${date.getUTCMonth()+1}/${date.getUTCDate()}/${date.getUTCFullYear()}`
}

export const MapCard = ({ transaction, disabled, onClick }: MapCardProps) => {
  return (
    <ButtonBase style={{ width: "100%" }} onClick={onClick} disabled={disabled}>
      <Card style={{ width: "100%" }}>
        <CardContent>
          <Box textAlign="center" width="100%" justifyContent="center" display="flex">
            <Avatar>
              <div />
            </Avatar>
          </Box>
          <Typography variant="h6" gutterBottom noWrap>
            {transaction.name || ""}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Balance component={Typography} variant="body1" color="textSecondary">
              {transaction.amount}
            </Balance>
            <Typography variant="body1" color="textSecondary">
              {toUtcDateString(transaction.date)}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

export default MapCard;
