import React, { useState, ReactNode } from "react";
import { IPaginationControls, TablePagination } from "./Pagination";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Menu from "@material-ui/core/Menu";
import FilterIcon from "@material-ui/icons/FilterList";
import { ToggleSelected } from "./Select";

export type ITableProps = {
  title: string;
  filters?: ReactNode;
  head?: ReactNode;
  body?: ReactNode;
  total?: number;
  rowsPerPageOptions?: number[];
};

export default ({
  title,
  filters,
  head,
  body,
  total = 0,
  rowsPerPageOptions = [5, 10, 25],
}: ITableProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <Card>
      <CardHeader
        title={title}
        action={
          filters ? (
            <Fab
              size="small"
              onClick={(ev: any) => setAnchorEl(ev.target)}
              color="primary"
            >
              <FilterIcon />
            </Fab>
          ) : null
        }
      />
      {filters ? (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {filters}
        </Menu>
      ) : null}
      <Divider />
      <CardContent>
        <Table>
          <TableHead>
            {head}
          </TableHead>
          <TableBody>
            {body}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions style={{ justifyContent: "flex-end" }}>
        <TablePagination
          count={total || 0}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </CardActions>
    </Card>
  );
};
