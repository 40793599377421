import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {
  IosInAppSubscriptionS2S
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import { NotificationTypeIcon } from "./NotificationTypeIcon"
import React from "react";

interface SubscriptionListItemProps {
  active?: boolean;
  subscription: IosInAppSubscriptionS2S;
  onClick?: () => any;
}

export const SubscriptionListItem = ({
  active,
  subscription,
  onClick,
}: SubscriptionListItemProps) => {
  return (
    <ListItem onClick={onClick} selected={active} button>
      <ListItemAvatar>
        <Avatar>
          <NotificationTypeIcon
            notification_type={subscription?.notification_type ?? ""}
          />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={subscription?.notification_type ?? ""}
        primaryTypographyProps={{ noWrap: true }}
        secondary={new Date(subscription?.createdDate).toLocaleString() ?? ""}
        secondaryTypographyProps={{ noWrap: true }}
      ></ListItemText>
      {/* <BoldBalance value={transaction.amount} /> */}
    </ListItem>
  );
};

export default SubscriptionListItem;
