import { PlaidTransaction } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { InstitutionsNotFoundModal } from "../../components/Institutions/NotFound";
import { useIsSmUp } from "../../components/Layout/utils";
import { useCachedTransactionsByAccount } from "../Dashboard/controls";
import { Map } from "../../components/Map";
import TransactionCards from "./TransactionCards";
import TransactionMarkers from "./TransactionMarkers";
import TransactionsCenter from "./TransactionsCenter";

export default () => {
  const history = useHistory();
  const isSmUp = useIsSmUp();
  const [
    activeTransaction,
    setActiveTransaction,
  ] = useState<PlaidTransaction | null>(null);
  const {
    transactions,
    institutions,
    locationTransactions,
    loading,
  } = useCachedTransactionsByAccount();

  return (
    <div
      style={{
        height: isSmUp ? "calc(100vh - 90px)" : "calc(100vh - 50px)",
        width: "100%",
        position: "relative",
      }}
    >
      <Map
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          outline: "none",
        }}
        options={{ center: [0, 0] }}
      >
        <TransactionMarkers transactions={locationTransactions} />
        <TransactionsCenter
          transactions={locationTransactions}
          activeTransaction={activeTransaction}
        />
      </Map>
      <TransactionCards
        transactions={locationTransactions}
        activeTransaction={activeTransaction}
        setActiveTransaction={setActiveTransaction}
      />
      <InstitutionsNotFoundModal
        open={!loading && !institutions.length}
        onClose={() => history.push("/")}
      />
    </div>
  );
};
