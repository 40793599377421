import {
  FindContextProvider,
  PaginationProvider,
  useFindContext,
  usePagination,
} from "@elevatejs/material-crud-ui";
import {
  CardUserDevice,
  CardUserDevicesDocument,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { FC, useMemo } from "react";
import { DeviceList } from "../../components/Devices/List";
import DevicesNotFound from "../../components/Devices/NotFound";
import { ExpandableTable } from "../../components/Table/Expandable";
import {
  useUserControlsContext,
  useSetUserDevicesExpanded,
  useSetActiveDeviceHawk,
  useUserDevicesExpanded,
  useActiveDeviceHawk,
} from "./controls";

export const CardUserDevices = () => {
  return (
    <PaginationProvider initialPerPage={5}>
      <CardUserDevicesProvider>
        <CardUserDevicesComponent />
      </CardUserDevicesProvider>
    </PaginationProvider>
  );
};

export const CardUserDevicesProvider: FC = ({
  children,
}) => {
  const { userId } = useUserControlsContext()
  const expanded = useUserDevicesExpanded();
  const [{ page, perPage }] = usePagination();
  const variables = useMemo(() => ({ userId, page, perPage }), [
    userId,
    page,
    perPage,
  ]);
  const options = useMemo(() => ({ skip: !expanded, notifyOnNetworkStatusChange: true }), [expanded]);
  return (
    <FindContextProvider
      typeName="InAppSubscription"
      options={options}
      variables={variables}
      findDocument={CardUserDevicesDocument}
      selectFindData={(v, data) => data?.cardUserById?.devices?.data ?? []}
      selectFindTotal={(v, data) => data?.cardUserById?.devices?.total ?? 0}
    >
      {children}
    </FindContextProvider>
  );
};

export const CardUserDevicesComponent: FC = () => {
  const { data, loading, refetch } = useFindContext<CardUserDevice[]>();
  const activeDevice = useActiveDeviceHawk();
  const setActiveDevice = useSetActiveDeviceHawk();
  const expanded = useUserDevicesExpanded();
  const setExpanded = useSetUserDevicesExpanded();
  return (
    <ExpandableTable
      title="Devices"
      loading={loading}
      expanded={expanded}
      setExpanded={() => setExpanded(!expanded)}
      onRefresh={refetch}
    >
      <DeviceList
        activeDevice={activeDevice}
        setActiveDevice={(v) => setActiveDevice(v)}
        devices={data}
        loading={loading}
      />
    </ExpandableTable>
  );
};
