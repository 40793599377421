import { CardUserDevice } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";
import { TableList } from "../Table/List";
import DeviceListItem from "./ListItem";
import DevicesNotFound from "./NotFound";

interface DeviceListProps {
  loading?: boolean;
  activeDevice?: CardUserDevice | null;
  setActiveDevice?: (v: CardUserDevice | null) => any;
  devices?: (CardUserDevice | null)[];
}

export const DeviceList = ({
  loading,
  devices = [],
  activeDevice,
  setActiveDevice,
}: DeviceListProps) => {
  return (
    <TableList
      loading={loading}
      items={devices}
      getItemId={(device) => device._id}
      renderItem={(device) => (
        <DeviceListItem
          device={device}
          active={device === activeDevice}
          onClick={
            setActiveDevice
              ? () => setActiveDevice(device === activeDevice ? null : device)
              : undefined
          }
        />
      )}
    >
      <DevicesNotFound />
    </TableList>
  );
};
