import React, { useState, useMemo } from 'react'
import { contextControllerHOC } from "../../utils/controls";
import MaterialTablePagination, { TablePaginationProps } from "@material-ui/core/TablePagination";

export type IPaginationControlsParams = {
  page?: number
  perPage?: number
};

export type IPaginationControls = ReturnType<
  typeof useBuildPaginationControls
>;

export const PaginationControls = contextControllerHOC(useBuildPaginationControls)

export function useBuildPaginationControls(initial: IPaginationControlsParams) {
  const [page, setPage] = useState(initial?.page ?? 0);
  const [perPage, setRowsPerPage] = useState(initial?.perPage ?? 10);
  const variables = useMemo(
    () => ({
      page: page + 1,
      perPage,
    }),
    [page, perPage]
  );

  const handlePageChange = (event: any, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value);
  };

  return {
    page,
    setPage,
    perPage,
    setRowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    variables,
  };
}

export const TablePagination = (props: Partial<TablePaginationProps>) => {
  const { page, perPage, handlePageChange, handleRowsPerPageChange } = PaginationControls.useControls()
  return <MaterialTablePagination
    onChangePage={handlePageChange}
    onChangeRowsPerPage={handleRowsPerPageChange}
    rowsPerPage={perPage}
    page={page}
    count={0}
    {...props}
  />
}