import {
  InAppSubscription,
  InAppSubscriptionError,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";
import { TableList } from "../Table/List";
import SubscriptionListItem from "./ListItem";
import SubscriptionErrorListItem from "./ErrorListItem";
import SubscriptionsNotFound from "./NotFound";

interface SubscriptionListProps {
  loading?: boolean;
  activeSubscription?: InAppSubscription | null;
  setActiveSubscription?: (v: InAppSubscription | null) => any;
  subscriptions?: (InAppSubscription | null)[];
}

export const SubscriptionList = ({
  loading,
  subscriptions = [],
  activeSubscription,
  setActiveSubscription,
}: SubscriptionListProps) => {
  return (
    <TableList
      loading={loading}
      items={subscriptions}
      getItemId={(subscription) => subscription._id}
      renderItem={(subscription) => (
        <SubscriptionListItem
          subscription={subscription}
          active={subscription === activeSubscription}
          onClick={
            setActiveSubscription
              ? () =>
                  setActiveSubscription(
                    subscription === activeSubscription ? null : subscription
                  )
              : undefined
          }
        />
      )}
    >
      <SubscriptionsNotFound />
    </TableList>
  );
};

interface SubscriptionErrorListProps {
  loading?: boolean;
  activeSubscriptionError?: InAppSubscriptionError | null;
  setActiveSubscriptionError?: (v: InAppSubscriptionError | null) => any;
  subscriptionErrors?: (InAppSubscriptionError | null)[];
}

export const SubscriptionErrorList = ({
  loading,
  subscriptionErrors = [],
  activeSubscriptionError,
  setActiveSubscriptionError,
}: SubscriptionErrorListProps) => {
  return (
    <TableList
      loading={loading}
      items={subscriptionErrors}
      getItemId={(subscriptionError) => subscriptionError._id}
      renderItem={(subscriptionError) => (
        <SubscriptionErrorListItem
          subscriptionError={subscriptionError}
          active={subscriptionError === activeSubscriptionError}
          onClick={
            setActiveSubscriptionError
              ? () =>
                  setActiveSubscriptionError(
                    subscriptionError === activeSubscriptionError
                      ? null
                      : subscriptionError
                  )
              : undefined
          }
        />
      )}
    >
      <SubscriptionsNotFound />
    </TableList>
  );
};
