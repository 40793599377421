import React, { useMemo } from "react";
import {
  PlaidTransaction,
  BackgroundLocation,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import { boundsFromCoords, FitBounds, FlyTo } from "../../components/Map";
import {
  locationToCoords,
  hasLocationLatLon,
  hasLatitudeLongitude,
  backgroundLocationToCoords,
} from "../../utils/location";

export function currentBounds(
  locations?: (BackgroundLocation | undefined | null)[],
  transactions?: (PlaidTransaction | undefined | null)[],
  shouldFitBounds?: boolean
) {
  if (!shouldFitBounds) return null;
  const t =
    (transactions &&
      transactions.filter(hasLocationLatLon).map(locationToCoords)) ||
    [];
  const l =
    (locations &&
      locations.filter(hasLatitudeLongitude).map(backgroundLocationToCoords)) ||
    [];
  return t.length || l.length
    ? boundsFromCoords((t).concat(l))
    : null;
}

export default ({
  locations,
  transactions,
  activeTransaction,
  shouldFitBounds,
}: {
  locations?: (BackgroundLocation | null)[];
  transactions?: (PlaidTransaction | undefined | null)[];
  activeTransaction: PlaidTransaction | undefined | null;
  shouldFitBounds?: boolean;
}) => {
  const bounds = useMemo(
    () => currentBounds(locations, transactions, shouldFitBounds),
    [locations, transactions, shouldFitBounds]
  );
  const coords = useMemo(
    () => activeTransaction && locationToCoords(activeTransaction),
    [activeTransaction]
  );
  return coords ? (
    <FlyTo zoom={16} coordinate={coords} />
  ) : bounds ? (
    <FitBounds bounds={bounds} />
  ) : null;
};
