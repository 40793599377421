import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { linkHandlerOptions } from "./options";

export function openPlaidLink() {
  const Plaid = (window as any)["Plaid"];
  if (typeof Plaid !== "undefined") {
    const handler = Plaid.create(
      Object.assign(linkHandlerOptions, {
        onSuccess: function (publicToken: string) {
          console.log({ publicToken });
          // TODO EXCHANGE PUBLIC TOKEN
        },
      })
    );
    handler.open();
  }
}

export const PlaidLinkFab = () => {
  return (
    <Fab color="primary" onClick={() => openPlaidLink()}>
      <AddIcon />
    </Fab>
  );
};
