import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useDashboardStatsQuery } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";
import { UnderConstruction } from "../../components/UnderConstruction";
import { useLatestSalesData } from "./data";
import LatestSales from "./LatestSales";
import TotalConfirmed from "./TotalConfirmed";
import TotalFalsified from "./TotalFalsified";
import TotalTransactions from "./TotalTransactions";
import TotalUsers from "./TotalUsers";
import UsersByDevice from "./UsersByDevice";

export default () => {
  const data = useLatestSalesData();
  const { data: stats, loading } = useDashboardStatsQuery();
  return (
    <>
    {loading ? <LinearProgress /> : null}
    <Box p={4}>
      {/* {loading ? <LinearProgress variant="indeterminate" /> : null} */}
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers total={stats?.users?.total ?? 0} loading={loading} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalTransactions
            total={stats?.transactions?.total_transactions ?? 0}
            loading={loading}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalConfirmed
            total={stats?.confirmed?.total_transactions ?? 0}
            loading={loading}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalFalsified
            total={stats?.falsified?.total_transactions ?? 0}
            loading={loading}
          />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <UnderConstruction>
            <LatestSales data={data} />
          </UnderConstruction>
        </Grid>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <UsersByDevice
            android={stats?.android?.total ?? 0}
            ios={stats?.ios?.total ?? 0}
            loading={loading}
          />
        </Grid>
        {/* <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <LatestProducts />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestOrders />
        </Grid> */}
      </Grid>
    </Box>
    </>
  );
};
