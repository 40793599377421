import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import webhooks from "./webhooks.svg"
import React from "react";

export const WebhooksNotFound = () => {
  return (
    <CardContent style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
      <Box textAlign="center">
        <img src={webhooks} alt="Credit Card Image" style={{ width: 180 }} />
        <Typography variant="h5">No Webhooks Found</Typography>
      </Box>
    </CardContent>
  );
};

export default WebhooksNotFound;
