import React, { FC } from 'react'
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import HistoryIcon from '@material-ui/icons/History';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';

export const WebhookExecutionStatusIcon: FC<{ type: string|undefined|null }> = ({ type, ...props }) => {
  switch (type) {
    case 'AGGREGATION': return <AssessmentIcon {...props} />
    case 'BALANCE': return <AccountBalanceWalletIcon {...props} />
    case 'HISTORY': return <HistoryIcon {...props} />
    case 'STATEMENT': return <AssignmentTurnedInIcon {...props} />
    case 'CONNECTION': return <SettingsInputHdmiIcon {...props} />
    default: return null
  }
}

export default WebhookExecutionStatusIcon