import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useIsMdUp } from "../../components/Layout/utils";
import { DeviceDetails } from "../../components/Devices/Details";
import { SubscriptionDetails } from "../../components/Subscriptions/Details";
import { SubscriptionS2SIosDetails } from "../../components/SubscriptionsS2SIos/Details";
// import { TransactionVerificationDialog } from "../../components/Transactions/Verification";
import { Transaction } from "../Transaction";
import { AtriumTransaction } from "../AtriumTransaction";
import { CardUserTransactions } from "./Transactions";
import { CardUserAtriumTransactions } from "./AtriumTransactions";
import {
  UserControlsProvider,
  useSetActiveDeviceHawk,
  useActiveDeviceHawk,
  useActiveSubscriptionHawk,
  useSetActiveSubscriptionHawk,
  useVerifyTransactionHawk,
  useSetVerifyTransactionHawk,
  useVerifyAtriumTransactionHawk,
  useSetVerifyAtriumTransactionHawk,
  useActiveSubscriptionS2SIosHawk,
  useSetActiveSubscriptionS2SIosHawk,
} from "./controls";
import { CardUserLocations } from "./Locations";
import { CardUserProfile } from "./Profile";
import { CardUserDevices } from "./Devices";
import { CardUserSubscriptions } from "./Subscriptions";
import { CardUserSubscriptionErrors } from "./SubscriptionErrors";
import { CardUserAtriumWebhooks } from "./AtriumWebhooks";
import { SubscriptionsS2SIos } from "./SubscriptionsS2SIos";
import { SubscriptionsS2SAndroid } from "./SubscriptionsS2SAndroid";

export default () => {
  const match = useRouteMatch<{ userId: string }>();
  const isMdUp = useIsMdUp();
  return (
    <UserControlsProvider userId={match.params.userId}>
      {/* {loading ? <LinearProgress variant="indeterminate" /> : null} */}
      <Box p={3}>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <CardUserProfile />
            {isMdUp ? (
              <>
                <Box pt={3}>
                  <CardUserTransactions />
                </Box>
                <Box pt={3}>
                  <CardUserAtriumTransactions />
                </Box>
                <Box pt={3}>
                  <CardUserSubscriptions />
                </Box>
                <Box pt={3}>
                  <CardUserSubscriptionErrors />
                </Box>
                <Box pt={3}>
                  <SubscriptionsS2SIos />
                </Box>
                <Box pt={3}>
                  <SubscriptionsS2SAndroid />
                </Box>
                <Box pt={3}>
                  <CardUserDevices />
                </Box>
                <Box pt={3}>
                  <CardUserAtriumWebhooks />
                </Box>
              </>
            ) : null}
          </Grid>
          <Grid item md={8} xs={12}>
            <Box position="sticky" top={64} className="scroll-sm" height="100%">
              <CardUserLocations />
            </Box>
          </Grid>
          {isMdUp ? null : (
            <>
              <Grid item xs={12}>
                <CardUserTransactions />
              </Grid>
              <Grid item xs={12}>
                <CardUserAtriumTransactions />
              </Grid>
              <Grid item xs={12}>
                <CardUserSubscriptions />
              </Grid>
              <Grid item xs={12}>
                <SubscriptionsS2SIos />
              </Grid>
              <Grid item xs={12}>
                <SubscriptionsS2SAndroid />
              </Grid>
              <Grid item xs={12}>
                <CardUserDevices />
              </Grid>
              <Grid item xs={12}>
                <CardUserAtriumWebhooks />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      {/* <Box position="fixed" bottom={0} right={0} padding={2}>
        <PlaidLinkFab />
      </Box> */}
      <CardsUserAtriumTransactionVerificationDialog />
      <CardsUserTransactionVerificationDialog />
      <CardsUserSubscriptionS2SIosDialog />
      <CardsUserSubscriptionDialog />
      <CardsUserDeviceDialog />
    </UserControlsProvider>
  );
};

const CardsUserDeviceDialog = () => {
  const activeDevice = useActiveDeviceHawk();
  const setActiveDevice = useSetActiveDeviceHawk();
  return (
    <Drawer
      anchor="left"
      open={Boolean(activeDevice)}
      onClose={() => setActiveDevice(null)}
      PaperProps={drawerClasses}
    >
      {activeDevice?.deviceId ? <DeviceDetails device={activeDevice} /> : null}
    </Drawer>
  );
};
const CardsUserSubscriptionDialog = () => {
  const activeSubscription = useActiveSubscriptionHawk();
  const setActiveSubscription = useSetActiveSubscriptionHawk();
  return (
    <Drawer
      anchor="left"
      open={Boolean(activeSubscription)}
      onClose={() => setActiveSubscription(null)}
      PaperProps={drawerClasses}
    >
      {activeSubscription?.productId ? (
        <SubscriptionDetails subscription={activeSubscription} />
      ) : null}
    </Drawer>
  );
};
const CardsUserSubscriptionS2SIosDialog = () => {
  const activeSubscription = useActiveSubscriptionS2SIosHawk();
  const setActiveSubscription = useSetActiveSubscriptionS2SIosHawk();
  return (
    <Drawer
      anchor="left"
      open={Boolean(activeSubscription)}
      onClose={() => setActiveSubscription(null)}
      PaperProps={drawerClasses}
    >
      {activeSubscription ? (
        <SubscriptionS2SIosDetails subscription={activeSubscription} />
      ) : null}
    </Drawer>
  );
};
const CardsUserTransactionVerificationDialog = () => {
  const verifyTransaction = useVerifyTransactionHawk();
  const setVerifyTransaction = useSetVerifyTransactionHawk();
  return (
    <Drawer
      anchor="left"
      open={Boolean(verifyTransaction)}
      onClose={() => setVerifyTransaction(null)}
      PaperProps={drawerClasses}
    >
      {verifyTransaction?.transaction_id ? (
        <Transaction transactionId={verifyTransaction?.transaction_id} />
      ) : null}
    </Drawer>
  );
};
const CardsUserAtriumTransactionVerificationDialog = () => {
  const verifyTransaction = useVerifyAtriumTransactionHawk();
  const setVerifyTransaction = useSetVerifyAtriumTransactionHawk();
  return (
    <Drawer
      anchor="left"
      open={Boolean(verifyTransaction)}
      onClose={() => setVerifyTransaction(null)}
      PaperProps={drawerClasses}
    >
      {verifyTransaction?.guid ? (
        <AtriumTransaction guid={verifyTransaction?.guid} />
      ) : null}
    </Drawer>
  );
};

const drawerClasses = {
  classes: {
    root: "main-drawer",
  },
};
