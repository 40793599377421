import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import { hawk, IHawkState, useHawkSetState, useHawkState } from "react-hawk";

export type ILocalStoreValues = string | boolean | number;
export type ILocalStoreMap = { [storeKey: string]: ILocalStoreValues };

export const LocalStoreContext = createContext<
  [ILocalStoreMap, Dispatch<SetStateAction<ILocalStoreMap>>] | null
>(null);

export function localStoreHawk<V> (opts: { key: string, default: V }) {
  return hawk({
    key: opts.key,
    default: safeLocalStoreGet(opts.key, opts.default) as V,
  });
}

export const useLocalStoreBooleanHawkSetState = (hawk: IHawkState<boolean>) => {
  const hawkState = useHawkState(hawk);
  const setHawkState = useHawkSetState(hawk);
  const setLocalStoreState: typeof setHawkState = (setter) => {
    const value = typeof setter === "function" ? setter(hawkState) : setter;
    setHawkState(value);
    safeLocalStoreSet(hawk.key, String(value));
  };
  return setLocalStoreState;
};

export const LocalStoreProvider: FC<{ initial?: ILocalStoreMap }> = ({
  children,
  initial = {},
}) => {
  const localValues = useMemo(() => {
    return Object.keys(initial).reduce((acc, key) => {
      acc[key] = safeLocalStoreGet(key, initial[key]);
      return acc;
    }, {} as ILocalStoreMap);
  }, [initial]);
  const contextValue = useState(localValues);
  return (
    <LocalStoreContext.Provider value={contextValue}>
      {children}
    </LocalStoreContext.Provider>
  );
};

export function safeLocalStoreGet<V>(
  key: string,
  initialValue: V
) {
  try {
    const value = localStorage.getItem(key);
    if (typeof value !== "string") return initialValue;
    if (typeof initialValue === "boolean") return value === "true";
    if (typeof initialValue === "number") return Number(value);
    return value;
  } catch (e) {
    return initialValue;
  }
}

export function safeLocalStoreSet<V>(key: string, value: V) {
  try {
    localStorage.setItem(key, String(value));
  } catch (e) {}
}
