import React, {
  createContext,
  useContext,
  FC,
  useState,
  useMemo,
  useEffect,
} from "react";
import {
  useVerifyAtriumTransactionLocationQuery,
  useSetTransactionVerificationStatusMutation,
  useAtriumTransactionActivityQuery,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import { getHost } from "@xyo-network/coin-plaid-client/build/lib/graphql.client";
import { contextControllerHOC } from "../../utils/controls";
import { env } from "../../providers/Plaid/options"

export type ITransactionControlsParams = {
  guid: string;
};

export type ITransactionControls = ReturnType<
  typeof useBuildTransactionControls
>;

export const TransactionControls = contextControllerHOC(
  useBuildTransactionControls
);

function useBuildTransactionControls({
  guid,
}: ITransactionControlsParams) {
  const [dayDelta, setDayDelta] = useState(1);
  const [activityPage, setActivityPage] = useState(0);
  const [activityPerPage, setActivityPerPage] = useState(5);
  const { data, loading } = useVerifyAtriumTransactionLocationQuery({
    variables: { guid },
    skip: !guid,
  });
  const activityVariables = useMemo(
    () => ({
      guid,
      pagination: {
        page: activityPage + 1,
        perPage: activityPerPage,
      },
    }),
    [guid, activityPage, activityPerPage]
  );
  const activityQuery = useAtriumTransactionActivityQuery({
    variables: activityVariables,
    skip: !guid,
  });
  const [
    setTransactionVerificationStatus,
    update,
  ] = useSetTransactionVerificationStatusMutation();
  const geoJSON = useTransactionGeoJson(guid, dayDelta);
  const verifyAtriumTransactionLocation = data?.verifyAtriumTransactionLocation;
  const account = verifyAtriumTransactionLocation?.account;
  const transaction = verifyAtriumTransactionLocation?.transaction;
  const institution = verifyAtriumTransactionLocation?.institution;
  const activityLoading = activityQuery.loading;
  const activityTotal =
    activityQuery.data?.verifyAtriumTransactionLocation?.activity?.total ?? 0;
  const activity =
    activityQuery.data?.verifyAtriumTransactionLocation?.activity?.data ?? [];
  const near = verifyAtriumTransactionLocation?.near;
  const during = verifyAtriumTransactionLocation?.during;
  return {
    loading,
    account,
    transaction,
    institution,
    near,
    during,
    setTransactionVerificationStatus,
    update,
    geoJSON,
    activityPage,
    setActivityPage,
    activityPerPage,
    setActivityPerPage,
    activityVariables,
    activityLoading,
    activityTotal,
    activity,
    dayDelta,
    setDayDelta
  };
}

function useTransactionGeoJson(guid: string, dayDelta: number = 1) {
  const [state, setState] = useState<{
    data: { features: any[] } | null;
    loading: boolean;
    error: string;
  }>({ data: null, loading: false, error: "" });

  const load = async (guid: string, dayDelta: number) => {
    try {
      setState({ data: null, loading: true, error: "" });
      const data = await fetchTransactionGeoJson(guid, dayDelta);
      setState({ data, loading: false, error: "" });
    } catch (e) {
      setState({ data: null, loading: false, error: e.message });
    }
  };

  useEffect(() => {
    load(guid, dayDelta);
  }, [guid, dayDelta]);

  return state;
}

async function fetchTransactionGeoJson(guid: string, dayDelta: number) {
  const res = await fetch(
    `${getHost(env)}/geo/json/transaction/atrium/${guid}?dayDelta=${dayDelta}`
  );
  return res.json();
}
