import {
  FindContextProvider,
  PaginationProvider,
  useFindContext,
  usePagination,
} from "@elevatejs/material-crud-ui";
import {
  CardUserSubscriptionErrorsDocument,
  InAppSubscriptionError,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { FC, useMemo } from "react";
import { SubscriptionErrorList } from "../../components/Subscriptions/List";
import { ExpandableTable } from "../../components/Table/Expandable";
import {
  useSetUserSubscriptionErrorsExpanded,
  useUserSubscriptionErrorsExpanded,
  useActiveSubscriptionErrorHawk,
  useSetActiveSubscriptionErrorHawk,
  useUserControlsContext
} from "./controls";

export const CardUserSubscriptionErrors = () => {
  return (
    <PaginationProvider initialPerPage={5}>
      <CardUserSubscriptionErrorsProvider>
        <CardUserSubscriptionErrorsComponent />
      </CardUserSubscriptionErrorsProvider>
    </PaginationProvider>
  );
};

export const CardUserSubscriptionErrorsProvider: FC = ({
  children,
}) => {
  const { userId } = useUserControlsContext()
  const expanded = useUserSubscriptionErrorsExpanded();
  const [{ page, perPage }] = usePagination();
  const variables = useMemo(() => ({ userId, page, perPage }), [
    userId,
    page,
    perPage,
  ]);
  const options = useMemo(() => ({ skip: !expanded, notifyOnNetworkStatusChange: true }), [expanded]);
  return (
    <FindContextProvider
      typeName="InAppSubscriptionError"
      variables={variables}
      options={options}
      findDocument={CardUserSubscriptionErrorsDocument}
      selectFindData={(v, data) =>
        data?.cardUserById?.getSubscriptionErrorHistory?.data ?? []
      }
      selectFindTotal={(v, data) =>
        data?.cardUserById?.getSubscriptionErrorHistory?.total ?? 0
      }
    >
      {children}
    </FindContextProvider>
  );
};

export const CardUserSubscriptionErrorsComponent: FC = () => {
  const { data, loading, refetch } = useFindContext<InAppSubscriptionError[]>();
  const activeSubscriptionError = useActiveSubscriptionErrorHawk();
  const setActiveSubscriptionError = useSetActiveSubscriptionErrorHawk();
  const expanded = useUserSubscriptionErrorsExpanded();
  const setExpanded = useSetUserSubscriptionErrorsExpanded();
  return (
    <ExpandableTable
      title="Subscription Errors"
      loading={loading}
      expanded={expanded}
      setExpanded={() => setExpanded(!expanded)}
      onRefresh={refetch}
    >
      <SubscriptionErrorList
        activeSubscriptionError={activeSubscriptionError}
        setActiveSubscriptionError={(v) => setActiveSubscriptionError(v)}
        subscriptionErrors={data}
        loading={loading}
      />
    </ExpandableTable>
  );
};
