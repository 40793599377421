import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import { PlaidInstitution } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React, { useCallback, useState } from "react";

interface InstitutionsListProps {
  institutions: PlaidInstitution[];
  selected: {
    [institution_id: string]: boolean;
  };
  setSelected: (institution_id: string, value: boolean) => any;
  toggleSelected: () => any;
}

export function isPlaidInstitution (v: any): v is PlaidInstitution {
  return Boolean(v && v.institution_id)
}

export const useInstitutionControls = (institutions: PlaidInstitution[]) => {
  const [selected, setState] = useState<InstitutionsListProps["selected"]>({});
  const setSelected = useCallback(
    (id: string, value: boolean) => {
      setState((v) => ({
        ...v,
        [id]: value,
      }));
    },
    [setState]
  );
  const toggleSelected = useCallback(() => {
    setState((v) =>
      institutions.reduce((acc: any, c: any) => {
        acc[c.institution_id] = !v[c.institution_id];
        return acc;
      }, {})
    );
  }, [institutions, setState]);
  return {
    institutions,
    selected,
    setSelected,
    toggleSelected,
  };
};

export const InstitutionsList = ({
  institutions,
  selected,
  setSelected,
  toggleSelected,
}: InstitutionsListProps) => {
  return (
    <Box>
      <Box
        px={2}
        pt={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5">Institutions</Typography>
        <Checkbox
          color="primary"
          indeterminate
          onChange={(ev, value) => toggleSelected()}
        />
      </Box>
      <List>
        {institutions.map((institution, i) => {
          const id = institution.institution_id || "";
          return (
            <ListItem
              key={id || i}
              button
              onClick={() => setSelected(id, !selected[id])}
            >
              <ListItemAvatar>
                {institution.logo ? (
                  <Avatar src={`data:image/png;base64,${institution.logo}`} />
                ) : (
                  <Avatar>
                    <div />
                  </Avatar>
                )}
              </ListItemAvatar>
              <Box ml={1} flex={1}>
                <Typography>
                  {(institution && institution.name) || ""}
                </Typography>
              </Box>
              <Checkbox
                color="primary"
                checked={selected[id] || false}
                onChange={(ev, value) => setSelected(id, value)}
              />
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
