import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import { AtriumTransaction } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import clsx from "clsx";
import "firebase/auth";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { BoldBalance } from "../Transactions/Balance";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
    overflow: "hidden",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

export interface DetailsProps {
  className?: string;
  transaction?: AtriumTransaction | null;
  loading?: boolean;
}

export const Details: FC<DetailsProps> = ({
  transaction,
  loading,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {loading ? (
        <Skeleton variant="circle" className={classes.avatar} />
      ) : (
        <Avatar
          alt="Category"
          className={classes.avatar}
          component={RouterLink}
          // src={(transaction && transaction.photoURL) || ""}
          to="/settings"
        />
      )}
      {loading ? (
        <Skeleton
          variant="rect"
          height={30}
          width={180}
          className={classes.name}
        />
      ) : (
        <Typography className={classes.name} variant="h4" noWrap style={{ maxWidth: '100%' }}>
          {transaction?.description ?? ''}&nbsp;
        </Typography>
      )}
      {loading ? (
        <Skeleton
          variant="rect"
          height={17}
          width={120}
          className={classes.name}
        />
      ) : (
        <BoldBalance value={transaction?.amount ?? 0} />
      )}
      {loading ? (
        <Skeleton
          variant="rect"
          height={17}
          width={120}
          className={classes.name}
        />
      ) : (
        <Typography variant="body2" noWrap style={{ maxWidth: '100%' }}>
          {transaction ? new Date(transaction.transactedAt || 0).toLocaleString() : ""}
          &nbsp;
        </Typography>
      )}
    </div>
  );
};

export default Details;
