import React, {
  Component,
  CSSProperties,
  createContext,
  useContext,
  useRef,
  useEffect,
  FC,
  useState,
} from "react";
import mapboxgl, { MapboxOptions } from "mapbox-gl";
import lomap from "lodash/map";
import get from "lodash/get";

export { default as heatmapOptions } from "./heatmap";
export { default as circlesOptions } from "./circles";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX || "";

const MapContext = createContext<MapState>({
  map: null,
});

export interface MapProps {
  children?: any;
  options?: Partial<MapboxOptions>;
  className?: string;
  style?: CSSProperties;
  onLoad?: Parameters<mapboxgl.Map["on"]>[1];
  onMove?: Parameters<mapboxgl.Map["on"]>[1];
  onZoom?: Parameters<mapboxgl.Map["on"]>[1];
  onDrag?: Parameters<mapboxgl.Map["on"]>[1];
}

export interface MapState {
  map: mapboxgl.Map | null;
}

export class Map extends Component<MapProps, MapState> {
  state: MapState = {
    map: null,
  };
  mapContainer: HTMLDivElement | null = null;

  componentDidMount() {
    if (this.mapContainer) {
      const map = new mapboxgl.Map({
        container: this.mapContainer,
        style: "mapbox://styles/mapbox/dark-v9",
        ...this.props.options,
      });

      const { onLoad, onMove, onZoom, onDrag } = this.props;
      map.on("load", () => this.setState({ map }));
      if (onLoad) map.on("load", onLoad);
      if (onMove) map.on("move", onMove);
      if (onZoom) map.on("zoom", onZoom);
      if (onDrag) map.on("drag", onDrag);
    }
  }

  render() {
    return (
      <MapContext.Provider value={this.state}>
        <div
          ref={(el) => (this.mapContainer = el)}
          style={this.props.style}
          className={this.props.className}
        >
          {this.props.children}
        </div>
      </MapContext.Provider>
    );
  }
}

const SourceContext = createContext<mapboxgl.Source | null>(null);
export const Source: FC<{
  id: string;
  data: any;
  fitBounds?: boolean;
}> = ({ id, data, fitBounds, children }) => {
  const { map } = useContext(MapContext);
  const [source, setSource] = useState<mapboxgl.Source | null>(null);
  useEffect(() => {
    console.log(map, data);
    if (map && data) {
      const source = map.getSource(id);
      if (source) {
        (source as any).setData(data);
      } else {
        map.addSource(id, {
          data,
          type: "geojson",
        });
      }
      setSource(map.getSource(id));
      if (data.features.length && fitBounds) {
        map.fitBounds(
          boundsFromCoords(lomap(data.features, "geometry.coordinates")),
          {
            padding: 100,
          }
        );
      }
    }
  }, [map, data, fitBounds]);

  return (
    <SourceContext.Provider value={source}>{children}</SourceContext.Provider>
  );
};

export const Layer = ({
  id,
  options,
  renderPopup,
}: {
  id: string;
  source: string;
  options: any;
  renderPopup?: (v: any) => string;
}) => {
  const { map } = useContext(MapContext);
  const source = useContext(SourceContext);
  useEffect(() => {
    function handleClick(e: any) {
      if (map && renderPopup) {
        new mapboxgl.Popup()
          .setLngLat(e?.features?.[0]?.geometry?.coordinates)
          .setHTML(renderPopup(e?.features?.[0]?.properties))
          .addTo(map);
      }
    }

    if (map && source && options) {
      const layer = map.getLayer(id);
      if (layer) {
        map.removeLayer(id);
      }
      map.addLayer(
        {
          id,
          source: get(source, "id"),
          ...options,
        },
        "waterway-label"
      );

      map.on("click", id, handleClick);
      return () => {
        map.off("click", id, handleClick);
        map.removeLayer(id);
      };
    }
  }, [map, source, options]);

  return null;
};

export const Marker = ({
  children,
  className,
  coordinates,
  onClick,
}: {
  coordinates: [number, number];
  className?: string;
  children?: string;
  onClick?: (ev: any) => any;
}) => {
  const { map } = useContext(MapContext);
  const markerEl = useRef<HTMLDivElement | null>(null);
  const marker = useRef<mapboxgl.Marker | null>(null);

  useEffect(() => {
    if (!marker.current && map && coordinates) {
      markerEl.current = document.createElement("div");
      if (children) {
        markerEl.current.innerText = children;
      }
      if (className) {
        markerEl.current.classList.add(className);
      }
      marker.current = new mapboxgl.Marker(markerEl.current)
        .setLngLat(coordinates)
        .addTo(map)
        .on("click", () => {
          console.log("Click");
        });
      if (onClick) {
        markerEl.current.addEventListener("click", onClick);
      }
    }

    return () => {
      if (markerEl.current && onClick) {
        markerEl.current.removeEventListener("click", onClick);
        marker.current?.remove();
        marker.current = null;
      }
    };
  }, [map, coordinates]);

  return null;
};

export const FitBounds = ({
  bounds,
  options = { padding: 100 },
}: {
  bounds: mapboxgl.LngLatBounds;
  options?: { padding: number };
}) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds, options);
    }
  }, [map, bounds]);

  return null;
};

export const boundsFromCoords = (coordinates: [number, number][]) => {
  const initial = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);
  return coordinates.reduce(
    (bounds, coord) => bounds.extend(coord as any),
    initial
  );
};

export const FlyTo = ({
  coordinate,
  zoom,
}: {
  coordinate: [number, number];
  zoom?: number;
}) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    if (map && coordinate) {
      const fly: any = {
        center: coordinate,
        essential: true,
      };
      if (zoom) fly.zoom = zoom;
      map.flyTo(fly);
    }
  }, [map, coordinate]);

  return null;
};
