import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import {
  AtriumWebhookExecution,
} from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import React from "react";
import WebhookExecutionListItem from "./WebhookExecutionListItem";
import WebhooksNotFound from "./WebhooksNotFound";

interface AtriumWebhookListProps {
  loading?: boolean
  activeExecution?: AtriumWebhookExecution | null;
  setActiveExecution?: (v: AtriumWebhookExecution | null) => any;
  webhookExecutions?: (AtriumWebhookExecution|null)[];
}

export const AtriumWebhookList = ({
  loading,
  webhookExecutions = [],
  activeExecution,
  setActiveExecution,
}: AtriumWebhookListProps) => {
  return (
    <Paper elevation={0} style={{ marginTop: -60, overflow: "hidden" }}>
      <Box style={{ height: 60 }}></Box>
      {webhookExecutions.length ? (
        <List>
          {webhookExecutions.map((execution, i) => execution && (
            <Slide
              direction="up"
              in={true}
              key={execution.id || i}
            >
              <Box>
                <WebhookExecutionListItem
                  execution={execution}
                  active={execution === activeExecution}
                  onClick={
                    setActiveExecution
                      ? () =>
                      setActiveExecution(
                        execution === activeExecution
                              ? null
                              : execution
                          )
                      : undefined
                  }
                />
              </Box>
            </Slide>
          ))}
        </List>
      ) : !loading ? (
        <Slide direction="up" in={true}>
          <Box py={5} textAlign="center">
            <WebhooksNotFound />
          </Box>
        </Slide>
      ) : null}
    </Paper>
  );
};
