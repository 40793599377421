import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import { openPlaidLink } from "../../providers/Plaid/Link";
import creditCard from "./credit_card.png";
import React from "react";

export const InstitutionsNotFoundModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => any;
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: "overflow-y-visible" }}
    >
      <InstitutionsNotFound />
    </Dialog>
  );
};

export const InstitutionsNotFound = () => {
  return (
    <Card style={{ overflow: "visible" }}>
      <CardContent>
        <Box textAlign="center">
          <img
            src={creditCard}
            alt="Credit Card Image"
            style={{ width: 140, marginTop: -30 }}
          />
          <Typography variant="h5">No Institutions Found</Typography>
          <Button onClick={openPlaidLink} variant="contained" color="primary">
            <AddIcon style={{ marginRight: 4 }} />
            Link Accounts
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default InstitutionsNotFound;
