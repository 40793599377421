import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import TablePagination from "@material-ui/core/TablePagination";
import LinearProgress from "@material-ui/core/LinearProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import { useVerifiableTransactionsPageAdminQuery } from "@xyo-network/coin-plaid-client/build/lib/graphql.generated";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { formatNumber } from "../../utils/number";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [selectedTransactions, setSelectedTransactions] = useState<string[]>(
    []
  );
  const [perPage, setRowsPerPage] = useState(10);
  const { data, loading } = useVerifiableTransactionsPageAdminQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      pagination: {
        page: page + 1,
        perPage,
      },
    },
  });
  const verifiableTransactionsPageAdmin =
    (data && data.verifiableTransactionsPageAdmin) || {};
  const transactions = verifiableTransactionsPageAdmin.transactions || [];
  // const accounts = verifiableTransactionsPageAdmin.accounts || [];
  const total = verifiableTransactionsPageAdmin.total_transactions || 0;

  const handleSelectAll = (event: any) => {
    if (event.target.checked) {
      setSelectedTransactions(
        (transactions || [])
          .map((transaction: any) => transaction && transaction.transaction_id)
          .filter((v: any) => v)
      );
    } else {
      setSelectedTransactions([]);
    }
  };

  const handleSelectOne = (event: any, uid: string) => {
    const selectedIndex = selectedTransactions.indexOf(uid);
    let newSelectedTransactions: string[] = [];

    if (selectedIndex === -1) {
      newSelectedTransactions = newSelectedTransactions.concat(
        selectedTransactions,
        uid
      );
    } else if (selectedIndex === 0) {
      newSelectedTransactions = newSelectedTransactions.concat(
        selectedTransactions.slice(1)
      );
    } else if (selectedIndex === selectedTransactions.length - 1) {
      newSelectedTransactions = newSelectedTransactions.concat(
        selectedTransactions.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedTransactions = newSelectedTransactions.concat(
        selectedTransactions.slice(0, selectedIndex),
        selectedTransactions.slice(selectedIndex + 1)
      );
    }

    setSelectedTransactions(newSelectedTransactions);
  };

  const handlePageChange = (event: any, page: number) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event: any) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Box p={3}>
        <Card>
          <CardHeader title="Notifications" />
          <Divider />
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selectedTransactions.length === transactions.length
                      }
                      color="primary"
                      indeterminate={
                        selectedTransactions.length > 0 &&
                        selectedTransactions.length < transactions.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(transactions || []).map(
                  (transaction) =>
                    transaction &&
                    transaction.transaction_id && (
                      <TableRow
                        hover
                        key={transaction.transaction_id}
                        selected={
                          selectedTransactions.indexOf(
                            transaction.transaction_id
                          ) !== -1
                        }
                        onClick={() =>
                          history.push(`/user/${transaction.transaction_id}`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selectedTransactions.indexOf(
                                transaction.transaction_id
                              ) !== -1
                            }
                            color="primary"
                            onChange={(event) =>
                              handleSelectOne(
                                event,
                                transaction.transaction_id || ""
                              )
                            }
                            value="true"
                          />
                        </TableCell>
                        <TableCell>
                          <div className={classes.nameContainer}>
                            {/* <Avatar
                              className={classes.avatar}
                              src={transaction.photoURL || undefined}
                            >
                              {getInitials(transaction.displayName)}
                            </Avatar> */}
                            <Typography variant="body1">
                              {transaction.name}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell>{`$${formatNumber(
                          transaction.amount || 0
                        )}`}</TableCell>
                        <TableCell>
                          {moment(transaction.created_date).format("MM/DD/YYYY")}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </CardContent>
          <CardActions style={{ justifyContent: 'flex-end' }}>
            <TablePagination
              component="div"
              count={total || 0}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </CardActions>
        </Card>
      </Box>
    </>
  );
};

const getInitials = (name: string | null = "") =>
  (name || "")
    .replace(/\s+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");
